function isCyrillicLetter(letter) {
  const cyrillicPattern = /^[\u0400-\u04FF]+$/;

  return cyrillicPattern.test(letter);
}

function extractCyrillicName(locale, namesString = '') {
  if (locale !== 'ru') return false;

  const namesArr = namesString.split(';');
  const alternateName = namesArr.find((el) => !!isCyrillicLetter(el[0]));

  if (!alternateName) return false;

  return alternateName.toString();
}

export default extractCyrillicName;
