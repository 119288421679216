import { prepareItem } from '../../utils/api/basket';
import * as basketSelectors from './basket';
import * as itemsSelectors from './items';

const selectBasketItemsToProcess = (state) => {
  const items = itemsSelectors.selectItemList(state);

  return basketSelectors.selectItems(state).map((basketItem) => {
    const founded = items.find((item) => item.id === basketItem.id);

    return founded
      ? { ...basketItem, ...prepareItem(founded), count: basketItem.count }
      : basketItem;
  });
};

export default selectBasketItemsToProcess;
