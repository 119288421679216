import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import makeStyles from './make-styles';
import { colors } from './common';

const useStyles = makeStyles({
  root: {
    color: '#000',
    background: colors.color03,
    border: `1px solid ${colors.color04}`,
    marginBottom: 20,
  },
});

const AlertCustom = React.forwardRef(({ children }, ref) => {
  const classes = useStyles();

  return (
    <Alert ref={ref} className={classNames(classes.root)} severity="error">
      {children}
    </Alert>
  );
});

AlertCustom.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default AlertCustom;
