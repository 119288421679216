import ActionType from '../../store/action-type';
import getPaymentProviders from './payment-providers';
import { store } from '../../store';
import { errorCodes } from './checkout-errors';

export const setCheckoutError = (code, text) => {
  store.dispatch({
    type: ActionType.Checkout.SetError,
    payload: { code, text: text || '' },
  });
};

export const getCheckoutError = () => store.getState().checkout.error;

export const setPaymentList = async (country) => {
  const { status, data: payload } = await getPaymentProviders(country);

  if (
    status === 'error' ||
    !Array.isArray(payload) ||
    payload.length === 0 ||
    !payload[0].name
  ) {
    setCheckoutError(errorCodes.PAYMENT_PROVIDERS);
    return;
  }

  setCheckoutError(errorCodes.OK);

  store.dispatch({
    type: ActionType.Checkout.GetPaymentMethods,
    payload,
  });
};

export const setShippingList = (payload) => {
  store.dispatch({
    type: ActionType.Checkout.GetShippingMethods,
    payload,
  });
};

export const setPackages = (payload) => {
  store.dispatch({
    type: ActionType.Checkout.GetPackages,
    payload,
  });
};
