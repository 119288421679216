export default [
  {
    id: 0,
    title: 'checkout.customer.title',
    short: 'checkout.customer.short',
    type: 'customer',
  },
  {
    id: 3,
    title: 'checkout.method.title',
    short: 'checkout.method.short',
    type: 'method',
  },
  {
    id: 4,
    title: 'checkout.payment.title',
    short: 'checkout.payment.title',
    type: 'payment',
  },
];
