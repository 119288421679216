import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrap } from './styled';
import Address from '../Address';
import Checkbox from '../ui/Checkbox';

import FormWrapContext from '../Form/context';
import { Title, Block, Dot } from '../Step/styled';

function Billing() {
  const {
    firstScreenValues,
    firstScreenErrors,

    handleChangeBilling,
    toggleSameAddressForShippingAndBilling,
  } = useContext(FormWrapContext);

  return (
    <Wrap>
      <Block>
        <Dot>3</Dot>
        <Title>
          <FormattedMessage id="checkout.billing.title" />
        </Title>
      </Block>
      <Checkbox
        name="address"
        value={firstScreenValues.isSameAddressForShippingAndBilling}
        onChange={toggleSameAddressForShippingAndBilling}
      >
        <FormattedMessage id="checkout.billing.address_same" />
      </Checkbox>
      {!firstScreenValues.isSameAddressForShippingAndBilling && (
        <Address
          values={firstScreenValues.billing}
          errors={firstScreenErrors.billing}
          onChange={handleChangeBilling}
          type="billing"
        />
      )}
    </Wrap>
  );
}

export default Billing;
