import styled from 'styled-components';
import { colors, media } from '../../../ui/common';

export const Wrap = styled.div`
  box-sizing: border-box;
  margin: 0;
  position: relative;
  ${media.laptop`
    position: fixed;
    min-width: 485px;
    height: 100vh;
    background: #f7fbfb;
    top: 0;
    right: 60px;
    height: 100%;
    &:before {
      display: none;
    }
  `};
  ${media.desktop`
    right: 100px;
  `}
`;
export const Summary = styled.span`
  background: #f8f8f8;
  margin: 0 -15px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  z-index: 1;
  span {
    font-size: 13px;
    padding: 0 9px;
    vertical-align: middle;
  }
  svg {
    vertical-align: middle;
  }
  &:before,
  &:after {
    height: calc(100% + 1px);
    background: #f8f8f8;
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
  }
  ${media.laptop`
    display: none;
  `};
`;
export const Card = styled.div`
  margin: 0 -15px;
  padding: 0 15px;
  box-sizing: border-box;
  max-height: ${(props) => (props.active ? 'none' : 0)};
  overflow: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition: 0.25s ease-out;
  position: relative;
  background: #f7fbfb;
  ${media.laptop`
    max-height: none;
    height: 100%;
    overflow: auto;
    padding-top: 97px;
    padding-right:  40px;
    padding-left:  40px;
  `};
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${colors.color05};
`;
export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px 0;
`;
export const Link = styled.a`
  font-size: 12px;
  color: ${colors.color01};
  text-decoration: underline;
`;
export const Result = styled.div`
  justify-content: space-between;
  padding: 26px 0;
  box-sizing: border-box;
  font-size: 18px;
  border-bottom: 1px solid #e4e4e4;
`;
export const Total = styled.div`
  font-weight: bold;
  color: ${colors.color05};
  text-align: right;
`;
export const Value = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  padding: 0 8px;
  text-decoration: line-through;
`;

export const EstimatedText = styled.div`
  margin-top: 0px;
  font-size: 12px;
  font-weight: normal;
  color: ${colors.color01};
`;
