import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { media } from '../ui/common';
import Container from '../ui/Container';
import Form from '../components/Checkout/Form';
import Header from '../components/Checkout/Header';
import Footer from '../components/Checkout/Footer';
import { mixpanelTrack } from '../utils/datalayer/dataLayer';
import useClearPayment from '../hooks/use-clear-payment';
import ActionType from '../store/action-type';

const Section = styled.section`
  overflow: hidden;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 568px;
  ${media.phone`
    margin: 0 auto;
  `};
  ${media.laptop`
    margin: 0;
  `};
`;

export default function Checkout() {
  const dispatch = useDispatch();
  const clearPayment = useClearPayment();
  useEffect(() => mixpanelTrack('checkout'));
  useEffect(() => {
    dispatch({
      type: ActionType.Basket.UpdateType,
      payload: {
        shipping: null,
        taxes: null,
        total: null,
        oldPrice: null,
        discount: null,
      },
    });
    clearPayment();
  }, []);
  return (
    <Section>
      <Container>
        <Wrap>
          <Header />
          <Form />
          <Footer />
        </Wrap>
      </Container>
    </Section>
  );
}
