import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useEvent } from 'react-use';
import { Actions, Next, Prev } from '../Form/styled';
import { Wrap, List, Row, Title, DeliveredTitle, LeftBlock } from './styled';
import useLocalizedCurrency from '../../../hooks/use-localized-currency';

function ShippingMethod({ value, onPrev, onEmit, ozonCity }) {
  const shippingList = useSelector((state) => state.checkout.shippingList);
  const packages = useSelector((state) => state.checkout.packages);
  const [values, setValues] = useState(null);
  const packagesString = packages
    .map(
      (pack) =>
        `[${pack.gross_weight_g}, ${pack.width_mm}, ${pack.height_mm}, ${pack.length_mm}]`
    )
    .join(',');
  const ozonPackages = `[${packagesString}]`;

  const isExists = (id) => shippingList.find((el) => el.id === id);
  const isOzonPickPoint = (id) => {
    const current = isExists(id);
    return current && current.title === 'Пункт выдачи OZON';
  };

  useEffect(() => {
    const defaultId = shippingList && shippingList[0] && shippingList[0].id;

    let id = defaultId;

    if (Object.keys(value).length > 0) {
      if (isExists(value.id)) {
        id = value.id;
      } else {
        id = defaultId;
      }
    }

    if (id) setValues(id);
  }, [shippingList]);

  const handleNext = async (event, additional) => {
    const obj = isExists(values);

    if (obj) {
      obj.additional = additional || {};
      onEmit('method', obj, true);
    }
  };
  function handleOzonData(event) {
    // Важно не слушать чужие события
    if (
      event.origin !== 'https://rocket.ozon.ru' ||
      typeof event.data !== 'string'
    )
      return;
    const data = JSON.parse(event.data);

    if (data.messageType !== 'OzonRocketWidgetSelectDeliveryVariant') return;
    const extraData = {
      delivery_type: data.type === 'Пункт выдачи' ? 'PickPoint' : 'Postamat',
      address: data.address,
      id: data.id,
    };
    handleNext(null, extraData);
  }

  const currency = useLocalizedCurrency();
  useEvent('message', handleOzonData);

  return (
    <Wrap>
      <List>
        {shippingList.map((el) => (
          <Row
            key={el.id}
            active={el.id === values}
            onClick={() => setValues(el.id)}
          >
            {el.duration === '' ? (
              <Title>{el.title}</Title>
            ) : (
              <LeftBlock>
                <Title>{el.title}</Title>
                <DeliveredTitle>
                  <FormattedMessage
                    id="checkout.shipping_method.delivered"
                    values={{ val: el.duration }}
                  />
                </DeliveredTitle>
              </LeftBlock>
            )}
            <Title>{`${el.price.split(' ')[0]} ${currency}`}</Title>
          </Row>
        ))}
      </List>
      {isOzonPickPoint(values) && (
        <div>
          <iframe
            title="Ozon widget"
            style={{
              width: '100%',
              height: '100%',
              'min-width': '320px',
              'min-height': '320px',
              border: 'none',
              overflow: 'hidden',
            }}
            src={`https://rocket.ozon.ru/lk/widget?token=DCGYcwEGPGTG10AukW7j3A%3D%3D&packages=${ozonPackages}&defaultcity=${ozonCity}&showdeliverytime=true&deliverytimemarkup=1`}
          >
            Браузер не поддерживает iframe
          </iframe>
        </div>
      )}
      <Actions>
        {!isOzonPickPoint(values) && (
          <Next onClick={handleNext}>
            <FormattedMessage id="checkout.billing.continue_payment" />
          </Next>
        )}
        <Prev onClick={() => onPrev()}>
          ⟵{' '}
          <span>
            <FormattedMessage id="checkout.shipping.return_customer" />
          </span>
        </Prev>
      </Actions>
    </Wrap>
  );
}

export default ShippingMethod;

ShippingMethod.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  onPrev: PropTypes.func.isRequired,
  onEmit: PropTypes.func.isRequired,
  ozonCity: PropTypes.string,
};

ShippingMethod.defaultProps = {
  ozonCity: 'Москва',
};
