import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import forOwn from 'lodash/forOwn';
import { FormattedPlural, FormattedMessage, useIntl } from 'react-intl';
import ActionType from '../../../store/action-type';
import Input from '../ui/Input';
import {
  Wrap,
  Summary,
  Top,
  Title,
  Link,
  Card,
  Result,
  Total,
  Value,
  EstimatedText,
} from './styled';
import {
  List,
  Text,
  Item,
  Image,
  Price,
  RightBlock,
  TextTop,
} from './styledList';
import {
  Promo,
  Button,
  Reset,
  Info,
  Row,
  Bottom,
  ButtonSecondary,
  ButtonSold,
  OnlyLeft,
} from './styledInfo';
import CartIcon from '../ui/cart';
import ArrowIcon from '../ui/arrow';
import {
  calculateItemsCount,
  isItemLeftCheck,
} from '../../../utils/api/basket';
import Basket from '../../Basket';
import formatPrice from '../../../utils/format-price';
import preparePlural from '../../../utils/intl';
import useI18nNavigation from '../../../hooks/use-i18n-navigation';
import Stock from '../../Basket/RowItem/Stock';
import ProductType from '../../../domain/product-type';
import useLocalizedCurrency from '../../../hooks/use-localized-currency';
import useBasket from '../../../hooks/use-basket';
import { selectDiscountErrorText } from '../../../store/selectors/basket';

function formatAttributes(item) {
  const attribs = [];
  forOwn(item.attributes, (value) => {
    attribs.push(value);
  });
  return attribs.join(', ');
}

function getItemIdentifier(item) {
  return item.name + formatAttributes(item);
}

function Cart({ opened }) {
  const intl = useIntl();
  const navigate = useI18nNavigation();
  const dispatch = useDispatch();
  const { removeItem, setItemCount, setPromoCode } = useBasket();
  const errorText = useSelector(selectDiscountErrorText);

  const {
    list: items,
    shipping: priceShipping,
    taxes,
    taxAmount,
    total,
    trueSubtotal,
    totalPriceWithDiscTaxed,
    cartSubtotal,
    promoCodeFinal,
    cartDiscount,
    discount,
  } = useSelector((state) => state.basket);

  const coolPrivilege = items.find(
    (item) => item.productType === ProductType.coolPrivilege
  );
  const coolPrivilegeCount = coolPrivilege ? coolPrivilege.count : 0;
  const coolPrivilegeTotal = coolPrivilege
    ? coolPrivilege.price * coolPrivilegeCount
    : 0;
  const finalItems = items.filter((item) => item !== coolPrivilege);

  const [active, setActive] = useState(true);
  const [values, setValues] = useState('');

  const promoCodeEntered = promoCodeFinal && promoCodeFinal !== '';
  const promoDiscount = cartDiscount || discount;
  const itemsCount = calculateItemsCount(items);
  const estimatedTotal = total === null;
  const currentTotal = estimatedTotal ? totalPriceWithDiscTaxed : total;
  const taxPrice = estimatedTotal ? taxAmount : taxes;

  const onChange = (type, value) => {
    setValues(value);
  };

  const onApply = () => {
    setPromoCode(values);
  };

  const onEditOrder = () => {
    dispatch({ type: ActionType.Basket.Visible, payload: true });
  };

  const onResetPromo = () => {
    setPromoCode('');
  };

  const currency = useLocalizedCurrency();

  useEffect(() => {
    setActive(opened);
  }, [opened]);

  return (
    <Wrap>
      <Basket />
      <Summary onClick={() => setActive(!active)}>
        <div>
          <CartIcon />
          <span>
            <FormattedMessage
              id={
                active
                  ? 'checkout.cart.hide_order_summary'
                  : 'checkout.cart.show_order_summary'
              }
            />
          </span>
          <ArrowIcon />
        </div>
        <div>{`${formatPrice(cartSubtotal)} ${currency}`}</div>
      </Summary>
      <Card active={active}>
        <Top>
          <Title>
            <FormattedMessage
              id="checkout.cart.your_cart"
              values={{ val: itemsCount }}
            />{' '}
            <FormattedPlural
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...preparePlural(intl, 'checkout.cart.item')}
              value={itemsCount}
            />
            )
          </Title>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link href="#" onClick={onEditOrder}>
            <FormattedMessage id="checkout.cart.edit_order" />
          </Link>
        </Top>
        <List>
          {finalItems.map((el) => {
            const {
              id,
              name,
              availableQuantity,
              count,
              oldPrice: itemPrice,
              img,
            } = el;
            const isEmpty = availableQuantity === 0;
            const realCount =
              count > availableQuantity ? availableQuantity : count;
            const isItemLeft = isItemLeftCheck(el);

            const clearAfter = () => {
              if (isEmpty) removeItem(el);
            };

            const onRemove = () => {
              navigate('/shop');

              // FIX: Do it after, because itemsCount hook is called before nav to shop.
              setTimeout(() => {
                clearAfter();
              }, 100);
            };

            const onClose = () => {
              if (availableQuantity > 0)
                setItemCount({ id, count: availableQuantity });

              clearAfter();
            };

            return (
              <React.Fragment key={getItemIdentifier(el)}>
                <Item>
                  <Image empty={isEmpty}>
                    <img src={img} alt="" />
                  </Image>
                  <Text empty={isEmpty}>
                    <TextTop error={isItemLeft}>
                      {count} x {name}
                    </TextTop>
                    {formatAttributes(el)}
                  </Text>
                  <RightBlock>
                    <Price empty={isEmpty}>
                      {`${formatPrice(itemPrice * realCount)} ${currency}`}
                    </Price>
                    {isEmpty ? (
                      <ButtonSold>
                        <Stock value={realCount} />
                      </ButtonSold>
                    ) : (
                      <div style={{ flexBasis: 15 }} />
                    )}
                  </RightBlock>
                </Item>
                {isItemLeft && (
                  <>
                    {!isEmpty && (
                      <OnlyLeft>
                        <FormattedMessage
                          id="checkout.cart.only_left"
                          values={{
                            val: realCount,
                            val2: (
                              <FormattedPlural
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...preparePlural(intl, 'checkout.cart.item')}
                                value={realCount}
                              />
                            ),
                          }}
                        />
                      </OnlyLeft>
                    )}
                    <Bottom>
                      <ButtonSecondary onClick={onRemove}>
                        <FormattedMessage id="checkout.cart.go_to_shop" />
                      </ButtonSecondary>
                      {!isEmpty && (
                        <ButtonSecondary onClick={onClose}>
                          <FormattedMessage
                            id="checkout.cart.okay_set_to"
                            values={{ val: availableQuantity }}
                          />
                        </ButtonSecondary>
                      )}
                    </Bottom>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </List>
        <Info>
          {!!coolPrivilegeCount && (
            <Row>
              <span>
                {coolPrivilegeCount} x{' '}
                <FormattedMessage id="checkout.cart.cool_privilege" />
              </span>
              <span>{`${formatPrice(coolPrivilegeTotal)} ${currency}`}</span>
            </Row>
          )}
          <Row>
            <span>
              <FormattedMessage id="checkout.cart.subtotal" />
            </span>
            <span>{`${formatPrice(trueSubtotal)} ${currency}`}</span>
          </Row>
          <Row>
            <span>
              <FormattedMessage id="checkout.cart.shipping" />
            </span>
            <span>{`${formatPrice(priceShipping)} ${currency}`}</span>
          </Row>
          <Row>
            <span>
              <FormattedMessage id="checkout.cart.taxes" />
            </span>
            <span>{`${formatPrice(taxPrice)} ${currency}`}</span>
          </Row>
          {promoCodeEntered ? (
            <Row>
              <span>
                <FormattedMessage
                  id="checkout.cart.promo_code"
                  values={{ val: promoCodeFinal }}
                />
                <Reset onClick={onResetPromo} />
              </span>
              <span>{`-${formatPrice(promoDiscount)} ${currency}`}</span>
            </Row>
          ) : (
            <Promo>
              <Input
                name="promo"
                label="checkout.cart.promo_code_title"
                onChange={onChange}
                value={values}
                error={errorText}
              />
              <Button onClick={onApply}>
                <FormattedMessage id="checkout.cart.apply" />
              </Button>
            </Promo>
          )}
        </Info>
        <Result>
          <Row>
            <Total>
              {estimatedTotal ? (
                <FormattedMessage id="checkout.cart.estimated_total" />
              ) : (
                <FormattedMessage id="checkout.cart.total" />
              )}
            </Total>
            <Total>
              {trueSubtotal > cartSubtotal && (
                <Value>{`${formatPrice(trueSubtotal)} ${currency}`}</Value>
              )}
              {`${formatPrice(currentTotal)} ${currency}`}
            </Total>
          </Row>
          {estimatedTotal && (
            <EstimatedText>
              <FormattedMessage id="checkout.cart.prices_after_shipping" />
            </EstimatedText>
          )}
        </Result>
      </Card>
    </Wrap>
  );
}

Cart.propTypes = {
  opened: PropTypes.bool,
};

Cart.defaultProps = {
  opened: false,
};

export default Cart;
