import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import { colors, media } from '../../../ui/common';
import Arrow from './arrow';
import extractCyrillicName from '../Address/extractCyrillicName';

const Wrap = styled.div`
  ${media.phone`
    margin-bottom: 5px; 
  `}
`;
const Field = styled.div`
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  position: relative;
  padding: 0 13px;

  select {
    width: 100%;
    border: none;
    padding: 15px 0 0;
    height: 44px;
    font-size: 14px;
    outline: none;
    color: #000000;
    background: transparent;
    appearance: none;
    position: relative;
    z-index: 1;
    &:focus + label {
      border-color: ${colors.color05};
      top: 6px;
      left: 13px;
      font-size: 11px;
    }
    &:focus {
      border-color: ${colors.color05};
    }
    select:focus ~ div {
      opacity: 0;
    }
  }
  svg {
    display: block;
    position: absolute;
    right: 13px;
    top: 14px;
    transform: rotate(90deg);
  }
`;

const Label = styled.label`
  position: absolute;
  font-size: ${(props) => (props.fixed ? '11px' : '14px')};
  top: ${(props) => (props.fixed ? '6px' : '15px')};
  left: ${(props) => (props.fixed ? '13px' : '15px')};
  transition: 0.25s ease-out;
  opacity: 0.7;
  color: ${colors.color01};
`;
const Error = styled.div`
  padding: 2px 0;
  min-height: 20px;
  font-size: 12px;
  line-height: 1.4;
  box-sizing: border-box;
  color: ${colors.error};
  transition: 0.25s ease-out;
  ${media.phone`
     min-height: 27px;
  `}
`;
const Tooltip = styled.div`
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 10px;
  color: ${colors.error};
`;

const creatableStyles = {
  container: (styles) => ({
    ...styles,
    zIndex: 2,
    fontSize: 14,
  }),
  control: (styles) => ({
    ...styles,
    height: 46,
    borderRadius: 4,
    boxShadow: '0 0 0 0',
  }),
};

const creatableTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colors.color05,
  },
});

const tempId = 65535;

const Select = React.forwardRef((props, ref) => {
  const {
    extended,
    name,
    value,
    options,
    onBlur,
    onChange,
    label,
    error,
    disabled,
    creatable,
    defaultValue,
    tooltip,
  } = props;
  const { locale } = useIntl();

  const handleBlur = () => {
    if (onBlur) onBlur(name);
  };

  const handleChange = (e) => {
    let data = e.target.value;

    if (extended) {
      data = options.find((v) => v.name === e.target.value);
    }

    onChange(name, data, true);
  };

  const titleValue = extended ? value.name : value;

  const savedValue = value.id !== tempId ? '' : value;

  const onPassChange = (v) => {
    onChange(name, { id: tempId, name: v, value: v, label: v });
  };

  useEffect(() => {
    if (!creatable) return;

    onChange(name, value);

    if (value.name !== '') handleBlur();
  }, [value.name]);

  return (
    <Wrap ref={ref}>
      {tooltip && (
        <Tooltip>
          <FormattedMessage id={tooltip} />
        </Tooltip>
      )}
      {creatable ? (
        <Creatable
          styles={creatableStyles}
          options={options.map((item) => {
            const cyrillicName = extractCyrillicName(
              locale,
              item.alternate_names
            );

            let selectValue;

            if (cyrillicName) {
              selectValue = cyrillicName;
            } else {
              selectValue = item.name;
            }

            return { label: selectValue, value: selectValue };
          })}
          theme={creatableTheme}
          placeholder={defaultValue.name}
          onChange={(v) => onPassChange(v.value)}
          value={savedValue}
          onBlur={handleBlur}
        />
      ) : (
        <Field error={error}>
          <Arrow />
          <select
            disabled={disabled}
            value={titleValue}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {options.map((el) => {
              const cyrillicName = extractCyrillicName(
                locale,
                el.alternate_names
              );

              let selectValue;

              if (!cyrillicName && extended) {
                selectValue = el.name;
              } else if (!cyrillicName && !extended) {
                selectValue = el;
              } else {
                selectValue = cyrillicName;
              }

              const techValue = extended ? el.name : el;

              return (
                <option value={techValue} key={techValue}>
                  {selectValue}
                </option>
              );
            })}
          </select>
          <Label fixed>
            <FormattedMessage id={label} />
          </Label>
        </Field>
      )}
      <Error> {error && <FormattedMessage id={error} />} </Error>
    </Wrap>
  );
});
export default Select;

/* eslint-disable  react/forbid-prop-types */
Select.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any.isRequired,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  extended: PropTypes.bool,
  disabled: PropTypes.bool,
  creatable: PropTypes.bool,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  tooltip: PropTypes.string,
};
/* eslint-enable  react/forbid-prop-types */

Select.defaultProps = {
  onBlur: null,
  value: '',
  label: '',
  error: '',
  extended: false,
  disabled: false,
  creatable: false,
  defaultValue: {},
  tooltip: null,
};
