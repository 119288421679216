import ActionType from '../../store/action-type';
import { store } from '../../store';

export const setPaymentData = (payload) => {
  store.dispatch({ type: ActionType.Payment.SetData, payload });
};

export const getPaymentData = () => store.getState().payment.data;

export const getPaymentId = () => store.getState().payment.id;
