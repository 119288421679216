import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useAffirm from '../../../../hooks/use-affirm';

import buttonIcon from './button.svg';
import { Button, Img } from './styled';

function Affirm({ onAuthorize, onError }) {
  const buttonRef = useRef();
  useAffirm({ buttonRef, onAuthorize, onError });

  return (
    <Button ref={buttonRef}>
      <Img src={buttonIcon} alt="Affirm logo" />
    </Button>
  );
}
Affirm.propTypes = {
  onAuthorize: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Affirm;
