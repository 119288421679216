import styled from 'styled-components';

// https://css-tricks.com/overriding-default-button-styles/
export const Button = styled.button`
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: #0fa0ea;
  border-radius: 6px;

  &:hover,
  &:focus {
    background-color: #0c80bb;
  }

  &:focus {
    outline: 2px solid #e59700;
  }

  &:active {
    background-color: #0caabb;
  }
`;

export const Img = styled.img`
  width: 205px;
`;
