import React, { createRef, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Wrap, Row } from './styled';
import Input from '../ui/Input';
import Checkbox from '../ui/Checkbox';
import Select from '../ui/Select';
import fetchRegionList from '../../../utils/api/fetchRegionList';
import fetchCityList from '../../../utils/api/fetchCityList';
import info from '../images/info.svg';
import { maxAddressLen, maxPhoneLen } from '../Form/validation';
import FormWrapContext from '../Form/context';
import PreviewPage from '../../preview-page';
import useApplyCountry from '../../../hooks/use-apply-country';
import { selectIsInvalidBasket } from '../../../store/selectors/basket';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
const WrapSelect = React.forwardRef((props, ref) => (
  <Select
    {...props}
    ref={ref}
    onChange={(...args) => {
      props.onChange(...args);

      const [type, value] = args;

      if (props.onCustomChange) props.onCustomChange(type, value);
    }}
  />
));
/* eslint-enable react/jsx-props-no-spreading */
/* eslint-enable react/prop-types */

/* eslint-disable no-return-assign */
function Address(props) {
  const applyCountry = useApplyCountry();
  const { values, errors, onChange, onBlur, type, isCityOnly } = props;
  const listRef = Object.keys(values).reduce(
    (acc, key) => ({ ...acc, [key]: createRef() }),
    {}
  );

  const isShipping = type === 'shipping';

  const intl = useIntl();
  const { showLoading, hideLoading } = useContext(FormWrapContext);

  const defaultRegion = {
    name: intl.formatMessage({ id: 'checkout.address.select_state' }),
    id: 0,
  };
  const defaultCity = {
    name: intl.formatMessage({ id: 'checkout.address.select_city' }),
    id: 0,
  };

  const isInvalidBasket = useSelector(selectIsInvalidBasket);

  const pickedCountry = useSelector((state) => state.countries.picked);
  const countryList = useSelector((state) => state.countries.list);
  const [regionList, setRegionList] = useState([defaultRegion]);
  const [cityList, setCityList] = useState([]);

  const [country, setCountry] = useState({ ...values.country });
  const [region, setRegion] = useState(values.state);
  const [city, setCity] = useState(values.city);

  const addCityList = async (pickedRegion) => {
    const response = await fetchCityList(country.code2, pickedRegion.id);
    if (!Array.isArray(response?.data)) return;

    setCityList([
      ...response.data,
      ...(response.data.length === 0 && pickedRegion.id > 0
        ? [{ id: pickedRegion.id, name: pickedRegion.name }]
        : []),
    ]);
  };

  const addRegionList = async (countryData) => {
    const response = await fetchRegionList(countryData?.code2 || pickedCountry);
    if (!Array.isArray(response?.data)) return;

    setRegionList([defaultRegion, ...response.data]);
    setRegion(defaultRegion);
  };

  const onChangeCity = (_, value) => {
    setCity(value);
  };

  const onChangeRegion = async (_, pickedRegion) => {
    setRegion(pickedRegion);
    await addCityList(pickedRegion);
    setCity({ name: '', id: 0 });
  };

  const onChangeCountry = async (_, value, callback) => {
    setCountry(value);
    setCityList([]);
    await addRegionList(value);

    if (callback) {
      await callback();
    } else {
      setCity({ name: '', id: 0 });
    }

    if (isShipping) {
      showLoading('loading.update_order');
      await applyCountry(value.code2);
      hideLoading();
    }
  };

  useEffect(() => {
    const fn = async () => {
      if (!region || !region.id || region.id === 0) {
        await addRegionList(country);
        setCityList([]);
        setCity({ name: '', id: 0 });
      } else {
        await onChangeCountry(null, values.country, async () => {
          setRegion(values.state);
          await addCityList(values.state);
          setCity(values.city);
        });
      }
    };

    fn();
  }, []);

  if (!pickedCountry) {
    return <PreviewPage redir="/" />;
  }

  return (
    <Wrap>
      <Row>
        <Input
          name="firstName"
          label="checkout.address.first_name"
          error={errors.firstName}
          value={values.firstName}
          onChange={onChange}
          onBlur={onBlur}
          autocomplete={`${type} given-name`}
        />
        <Input
          name="lastName"
          label="checkout.address.last_name"
          error={errors.lastName}
          value={values.lastName}
          onChange={onChange}
          onBlur={onBlur}
          autocomplete={`${type} family-name`}
        />
      </Row>
      <WrapSelect
        name="country"
        label="checkout.address.select_country"
        error={errors.country}
        value={country}
        options={countryList}
        onChange={onChange}
        onCustomChange={onChangeCountry}
        extended
        disabled={isInvalidBasket}
        onBlur={onBlur}
      />
      <Row>
        <WrapSelect
          name="state"
          label="checkout.address.select_state"
          error={errors.state}
          value={region}
          options={regionList}
          onChange={onChange}
          onCustomChange={onChangeRegion}
          extended
          onBlur={onBlur}
          ref={(r) => (listRef.state = r)}
        />
        <Input
          name="postalCode"
          label="checkout.address.postal_code"
          error={errors.postalCode}
          value={values.postalCode}
          onChange={onChange}
          onBlur={onBlur}
          autocomplete={`${type} postal-code`}
        />
      </Row>
      <WrapSelect
        name="city"
        label="checkout.address.select_city"
        error={errors.city}
        value={city}
        options={cityList}
        onChange={onChange}
        onCustomChange={onChangeCity}
        defaultValue={defaultCity}
        extended
        creatable
        tooltip={pickedCountry === 'AU' ? 'checkout.address.suburb' : null}
        onBlur={onBlur}
        ref={(r) => (listRef.city = r)}
      />
      {!isCityOnly && (
        <>
          <Input
            name="street"
            label="checkout.address.address1"
            error={errors.street}
            value={values.street}
            onChange={onChange}
            onBlur={onBlur}
            autocomplete={`${type} address-line1`}
            tooltip="checkout.address.address1_tooltip"
            icon={info}
            additional={{
              maxLength: maxAddressLen.toString(),
            }}
          />
          <Input
            name="info"
            label="checkout.address.address2"
            value={values.info}
            error={errors.info}
            onChange={onChange}
            onBlur={onBlur}
            autocomplete={`${type} address-line2`}
            tooltip="checkout.address.address2_tooltip"
            icon={info}
            additional={{
              maxLength: maxAddressLen.toString(),
            }}
          />
        </>
      )}
      <Input
        name="phone"
        label="checkout.address.phone"
        error={errors.phone}
        value={values.phone}
        onChange={onChange}
        onBlur={onBlur}
        autocomplete={`${type} tel`}
        tooltip="checkout.address.phone_tooltip"
        icon={info}
        additional={{
          maxLength: maxPhoneLen.toString(),
        }}
      />
      {isShipping && (
        <>
          <Checkbox
            name="terms"
            value={values.terms}
            error={errors.terms}
            onChange={onChange}
            onBlur={onBlur}
            ref={(r) => (listRef.terms = r)}
          >
            <FormattedMessage id="checkout.address.terms" />
          </Checkbox>
          <Checkbox
            name="newsletter"
            value={values.newsletter}
            error={errors.newsletter}
            onChange={onChange}
          >
            <FormattedMessage id="checkout.address.newsletter" />
          </Checkbox>
        </>
      )}
    </Wrap>
  );
}
/* eslint-enable no-return-assign */

export default Address;

Address.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['shipping', 'billing']),
  isCityOnly: PropTypes.bool.isRequired,
};
Address.defaultProps = {
  onBlur: null,
  type: 'shipping',
};
