import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ScriptLoader from 'react-script-loader-hoc';

const payPalENV = process.env.GATSBY_PAYPAL_ENV || 'sandbox';

/**
 *
 * @param scriptsLoadedSuccessfully
 * @param onPayment Triggered, when paypal button is pressed. Should return paypal token (internal_prepayment_data)
 * if paypal is a payment method
 * @param onAuthorize(response) Triggered, when payment is complete, call update order session
 * with external_prepayment_data = response.payerID.
 * Pass it to the orderUpdate: (response1) => Success status: response1.data.payment_is_captured --- bool.
 * @param onError
 * @returns {*}
 * @constructor
 */
function Paypal({
  scriptsLoadedSuccessfully,
  onPayment,
  onAuthorize,
  onError,
}) {
  if (!scriptsLoadedSuccessfully) {
    return <>Loading...</>;
  }
  const PayPalButton = window.paypal.Button.driver('react', {
    React,
    ReactDOM,
  });
  return (
    <PayPalButton
      commit
      env={payPalENV}
      payment={onPayment}
      onAuthorize={onAuthorize}
      onCancel={() => {}}
      onError={onError}
    />
  );
}

export default ScriptLoader('https://www.paypalobjects.com/api/checkout.js')(
  Paypal
);

Paypal.propTypes = {
  scriptsLoadedSuccessfully: PropTypes.bool.isRequired,
  onPayment: PropTypes.func.isRequired,
  onAuthorize: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

Paypal.defaultProps = {
  onError: () => {},
};
