import styled from 'styled-components';
import { media } from '../../../ui/common';

export const Wrap = styled.div`
  ${media.phone`
    padding-top: 20px;
  `}
`;
export const Row = styled.div`
  ${media.phone`
    display: flex;
    justify-content: space-between;
    & > div {
      width: 48%;
    }
  `}
`;
