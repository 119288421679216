import axios from 'axios';
import { getApiURL } from '../api';

const fetchCityList = (countryCode, regionId) =>
  axios.get(`${getApiURL()}v0/geodata/cities/`, {
    params: {
      country__code2: countryCode.toUpperCase(),
      region__id: regionId,
    },
  });

export default fetchCityList;
