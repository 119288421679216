import { isProduction } from '../../../utils/settings';

export const DefaultCustomer = isProduction
  ? {
      email: '',
      offers: false,
    }
  : {
      email: 'test@evapolar.com',
      offers: false,
    };

export const DefaultShipping = isProduction
  ? {
      firstName: '',
      lastName: '',
      state: { name: '' },
      postalCode: '',
      city: { name: '' },
      street: '',
      info: '',
      phone: '',
      terms: false,
      newsletter: false,
    }
  : {
      firstName: 'Test',
      lastName: 'User',
      state: { name: '' },
      postalCode: '02081',
      city: { name: '' },
      street: 'Rave Avenue',
      info: '',
      phone: '4155555555',
      terms: true,
      newsletter: false,
    };
