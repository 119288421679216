import styled from 'styled-components';

export const Wrap = styled.div``;

export const Note = styled.div`
  font-size: 13px;
  padding: 20px 0;
`;
export const Text = styled.div`
  font-size: 14px;
  padding-top: 28px;
  line-height: 2;
`;
