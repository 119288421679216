import { colors } from '../../../ui/common';

export default () => `{
  padding: 14px 16px 14px 48px;
  position: relative;
 
  &:before {
    position: absolute;
    left: 18px;
    top: 13px;
    content: '';
    width: 19px;
    height: 19px;
    border: 1px solid ${colors.color05};
    box-sizing: border-box;
    border-radius: 50%;
  }
  &:after {
    position: absolute;
    content: '';
    left: 23px;
    top: 18px;
    width: 9px;
    height: 9px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 50%;
  }
}`;
