import styled from 'styled-components';
import { Link as LinkDefault } from 'gatsby';
import { colors, media } from '../../../ui/common';

export const Section = styled.footer`
  padding: 18px 0;
  border-top: 1px solid #e4e4e4;
  margin-top: auto;
  ${media.phone`
    padding: 24px 0;
  `}
`;
export const Row = styled.div`
  display: flex;
  margin: 0 -10px;
  ${media.laptop`
    margin: 0 -8px;
  `}
`;
export const Link = styled(LinkDefault)`
  color: ${colors.color01};
  font-size: 12px;
  text-decoration: underline;
  padding: 8px 8px;
  box-sizing: border-box;
  &:hover {
    text-decoration: none;
  }
`;
