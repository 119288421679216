import { getStore } from '../../store';

export const getVariants = (items) => {
  // TODO Move to selector
  const basketList = getStore().getState().basket.list;

  const list = items || basketList;

  return list.map((value) => ({
    variant: value.id,
    quantity: value.count,
  }));
};

export const getAvailableVariants = (items) => {
  const list = getVariants(items);

  return list.filter((value) => value.quantity > 0);
};
