import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Loading, Spinner } from './styledLoader';

function OrderProcess({ isLoading, title }) {
  return (
    isLoading && (
      <Loading>
        <div>
          <Spinner />
          <p>
            <FormattedMessage id={title} />
          </p>
        </div>
      </Loading>
    )
  );
}

OrderProcess.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default OrderProcess;
