import styled, { css } from 'styled-components';
import { colors } from '../../../ui/common';

const opacityMod = css`
  opacity: ${(props) => (props.empty ? '0.3' : '1.0')};
`;
export const List = styled.div``;
export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  box-sizing: border-box;
`;
export const Image = styled.div`
  width: 54px;
  height: 54px;
  img {
    width: 100%;
  }
  ${opacityMod};
`;
export const Text = styled.div`
  font-size: 12px;
  padding: 0 20px;
  box-sizing: border-box;
  p {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 10px;
  }
  ${opacityMod};
`;
export const TextTop = styled.p`
  ${(props) => props.error && `color: ${colors.error};`}
`;
export const RightBlock = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const Price = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  ${opacityMod};
`;
