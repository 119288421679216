import styled from 'styled-components';
import LocalizedLink from '../../localized-link';

export const Section = styled.header`
  padding: 14px 0;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
`;
export const Block = styled.div`
  display: flex;
  padding: 0 21px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  span,
  img {
    vertical-align: middle;
  }
  img {
    margin-right: 6px;
  }
`;
export const LogoLocalizedLink = styled(LocalizedLink)`
  display: flex;
  align-items: center;
`;
