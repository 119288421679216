import React from 'react';

export default function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      hanging="16"
      fill="none"
    >
      <path
        opacity=".5"
        d="M5.757 12L10 7.759 5.757 3.515"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
