import styled from 'styled-components';
import { primary, secondary } from '../../../ui/buttonDefault';
import { colors, media, mediaMax } from '../../../ui/common';

export const Info = styled.div`
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  font-size: 14px;
  padding: 14px 0;
  box-sizing: border-box;
  color: ${colors.color01};
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  box-sizing: border-box;
`;
export const Promo = styled.div`
  padding: 6px 0;
  box-sizing: border-box;
  ${media.phone`
    display: flex;
    > div {width: 100%;}
  `}
`;
export const Button = styled.button`
  ${primary};
  width: 100%;
  height: 46px;
  ${media.phone`
    min-width: 140px;
    width: 30%;
    margin-left: 16px;
  `}
`;
export const Reset = styled.button`
  min-width: 18px;
  width: 19px;
  height: 19px;
  position: relative;
  border-radius: 50%;
  background: none;
  border: 1px solid;
  vertical-align: middle;
  margin-left: 6px;
  box-sizing: border-box;
  transition: 0.15s ease-out;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    width: 1px;
    height: 10px;
    top: 4px;
    left: 8px;
    background: currentColor;
    position: absolute;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    color: ${colors.color05};
  }
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ButtonSecondary = styled.button.attrs(() => ({
  type: 'button',
}))`
  ${secondary};
  min-width: 183px;
  height: 36px;
  line-height: 36px;
  ${mediaMax.phone`
    min-width: 135px;
  `}
`;
export const ButtonSold = styled.button.attrs(() => ({
  type: 'button',
}))`
  ${primary};
  min-width: 114px;
  height: 26px;
  line-height: 26px;
  color: ${colors.color01};
  background: #e9e9e9;
  pointer-events: none;
`;
export const OnlyLeft = styled.div`
  margin: -10px 0 10px;
  color: ${colors.error};
`;
