import styled from 'styled-components';
import { colors, media, mediaMax } from '../../../ui/common';
import { primary, button } from '../../../ui/buttonDefault';

export const Block = styled.div`
  padding: 16px 0;
  box-sizing: border-box;
  &:before {
    counter-increment: list;
    content: counter(list);
    display: inline-block;
    width: 25px;
    height: 25px;
    font-size: 14px;
    color: #ffffff;
    line-height: 25px;
    border-radius: 50%;
    margin-right: 16px;
    background-color: ${colors.color05};
    text-align: center;
  }
`;
export const Prev = styled.button`
  ${button};
  width: 100%;
  height: 66px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.color01};
  span {
    text-decoration: underline;
    padding: 0 4px;
    font-weight: normal;
  }
  ${media.phone`
    width: auto;
    padding: 0;
  `}
`;
export const Next = styled.button`
  ${primary};
  height: 66px;
  width: 100%;
  font-weight: normal;
  margin-left: auto;
  margin-top: 5px;
  ${media.phone`
    width: auto;
    min-width: auto;
    padding: 0px 40px;
  `}
`;
export const Actions = styled.div`
  padding: 20px 0;
  box-sizing: border-box;
  ${media.phone`
    padding: 28px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  `}
`;

export const Breadcrumbs = styled.div`
  display: flex;
  margin: 20px -7px 32px;
  ${mediaMax.tablet`
    flex-wrap: wrap;
  `}
  svg {
    vertical-align: middle;
  }
  button {
    color: #000;
    font-size: 12px;
    text-decoration: none;
    background: none;
    border: none;
  }
  ${media.laptop`
    margin: 34px -7px 34px;
  `}
`;
export const Paid = styled.div`
  margin: 42px 0;
`;
export const Title = styled.h2`
  font-weight: 600;
  font-size: 32px;
  margin: 0;
  color: ${colors.color05};
  ${media.phone`
     font-size: 40px;
  `}
`;
export const Text = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.9);
  padding: 24px 0;
  p {
    margin: 0;
  }
  b {
    font-weight: bold;
  }
  a:link {
    color: blue;
  }
}
`;
export const Button = styled.button`
  ${primary};
  height: 70px;
  font-weight: normal;
  line-height: 68px;
  padding: 0 30px;
  margin-top: 8px;
`;
export const BreadcrumbItem = styled.span`
  display: flex;
`;
