import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Input from '../ui/Input';
import { Wrap } from './styled';
import FormWrapContext from '../Form/context';

function Customer({ values }) {
  const { firstScreenErrors, handleChangeCustomer } =
    useContext(FormWrapContext);

  return (
    <Wrap>
      <Input
        type="email"
        name="email"
        label="checkout.customer.email"
        error={firstScreenErrors.customer.email}
        value={values.customer.email}
        onChange={handleChangeCustomer}
        autocomplete="email"
      />
    </Wrap>
  );
}

Customer.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Customer;
