import styled from 'styled-components';
import radio from '../ui/Radio';
import { colors, media } from '../../../ui/common';

export const List = styled.div`
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 32px 0 5px;
`;
export const Row = styled.div`
  ${radio};
  &:after {
    background: ${(props) => (props.active ? colors.color05 : 'transparent')};
  }
  ${media.phone`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 9px 16px 9px 48px;
  `}
  img {
    display: block;
  }
`;
export const Text = styled.div`
  font-size: 14px;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.7);
`;
export const Block = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 14px 10px 12px;
  &:last-of-type {
    border-bottom: none;
  }
  ${media.phone`
     padding: 22px 10px 22px;
  `}
`;
export const Body = styled.div`
  padding: 0 16px;
  box-sizing: border-box;
  max-height: ${(props) => (props.active ? 'none' : 0)};
  overflow: ${(props) => (props.active ? 'none' : 'hidden')};
`;
export const Logo = styled.div`
  margin-top: -4px;
  ${media.phone`
    margin-top: 0;
  `}
`;
export const Services = styled.div`
  padding-top: 12px;
  ${media.phone`
    padding-top: 0;
    margin-top: -4px;
  `}
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${colors.color05};
  padding-top: 5px;
  ${media.phone`
    margin-bottom: 0;
  `}
`;
