import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Address from '../Address';
import { Wrap, Note } from './styled';
import FormWrapContext from '../Form/context';
import { Title, Dot } from '../Step/styled';

function Shipping({ isCityOnly }) {
  const {
    firstScreenValues,
    firstScreenErrors,

    handleChangeShipping,
  } = useContext(FormWrapContext);

  return (
    <Wrap>
      <Dot>2</Dot>
      <Title>
        <FormattedMessage id="checkout.shipping.title" />
      </Title>
      <Address
        values={firstScreenValues.shipping}
        errors={firstScreenErrors.shipping}
        onChange={handleChangeShipping}
        type="shipping"
        isCityOnly={isCityOnly}
      />
      <Note>
        <FormattedMessage id="checkout.shipping.required_fields" />
      </Note>
    </Wrap>
  );
}

export default Shipping;

Shipping.propTypes = {
  isCityOnly: PropTypes.bool.isRequired,
};
Shipping.defaultProps = {};
