import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PaymentPageSdk from '@raiffeisen-ecom/payment-sdk';
import { FormattedMessage } from 'react-intl';
import { getSessionId } from '../../../../utils/api/session';
import { Button } from '../../../../ui/buttonDefault';

function Raiffeisen({ onAuthorize, onError }) {
  const total = useSelector((state) => state.basket.total);
  const sessionId = getSessionId();
  const paymentPage = new PaymentPageSdk(process.env.GATSBY_RAIFF_PUBLIC_KEY, {
    url: process.env.GATSBY_RAIFF_URL,
  });
  const handleClick = (e) => {
    paymentPage
      .openPopup({
        amount: total,
        orderId: sessionId,
        extra: {
          additionalInfo: `This is order Number ${sessionId}`,
        },
        paymentMethod: '',
      })
      .then(() => onAuthorize(sessionId))
      .catch(() => onError(e.message));
  };

  return (
    <Button onClick={handleClick}>
      <FormattedMessage id="checkout.payment.pay" />
    </Button>
  );
}

Raiffeisen.propTypes = {
  onAuthorize: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Raiffeisen;
