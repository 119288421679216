import styled from 'styled-components';
import { media } from '../../../../ui/common';

export const Wrap = styled.div`
  .StripeElement {
    border: 1px solid rgba(1, 62, 62, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    height: 46px;
    padding: 14px 12px;
    margin-bottom: 16px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
  ${media.phone`
    flex-wrap: nowrap;
  `};
`;

export const Number = styled.div`
  width: 100%;
  ${media.phone`
    width: 50%;
    flex-wrap: nowrap;
  `};
`;
export const Block = styled.div`
  width: 48%;
  ${media.phone`
    width: 25%;
    flex-wrap: nowrap;
    margin-left: 16px;
  `};
`;
