import Big from 'big.js';
import rawMinorUnitExponentByCode from './minor-unit-exponent-by-code.json';

const minorUnitExponentByCode = rawMinorUnitExponentByCode.reduce(
  (accumulator, entry) => accumulator.set(entry[0], entry[1]),
  new Map()
);

export default function getAmountInMinorUnits(amount, currencyCode) {
  const minorUnitExponent = minorUnitExponentByCode.get(currencyCode);
  const big = new Big(amount).times(10 ** minorUnitExponent);
  const amountInMinorUnitsAsString = big.toFixed();
  if (amountInMinorUnitsAsString.length !== big.e + 1) {
    throw new Error('amount in minor units must be integer');
  }
  return Number.parseInt(amountInMinorUnitsAsString, 10);
}
