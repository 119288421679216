import styled from 'styled-components';
import { media } from '../../../ui/common';

export const Wrap = styled.div``;
export const Row = styled.div`
  position: relative;
  padding: 20px 20px 5px;
  margin: 44px 0 0;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  ${media.phone`
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 55px;
  `}
`;
export const Label = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 4px 8px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
`;
export const Button = styled.a`
  text-decoration: none;
  height: 42px;
  background: ${(props) => (props.yellow ? '#F7C557' : 'transparent')};
  border: 1px solid ${(props) => (props.yellow ? '#F7C557' : '#c4c4c4')};
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
  }
  ${media.phone`
    width: 160px;
    margin: 6px 16px;
  `}
`;
