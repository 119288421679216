import { useDispatch } from 'react-redux';
import { setSessionId } from '../utils/api/session';
import ActionType from '../store/action-type';

const useClearPayment = () => {
  const dispatch = useDispatch();

  return () => {
    setSessionId(null);
    dispatch({ type: ActionType.Payment.SetId, payload: null });
    dispatch({ type: ActionType.Payment.SetData, payload: '' });
    dispatch({
      type: ActionType.Checkout.SetError,
      payload: { code: 0, text: '' },
    });
  };
};

export default useClearPayment;
