import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Alert from '../../../ui/alert';
import { getCheckoutErrorText } from '../../../utils/api/checkout-errors';

const OrderError = React.forwardRef(({ error }, ref) => {
  const text = getCheckoutErrorText(error.code);

  if (text === '') return null;

  return (
    <Alert ref={ref}>
      <FormattedMessage id={text} />
      {typeof error.text === 'string' && error.text !== '' && error.text}
    </Alert>
  );
});

OrderError.propTypes = {
  error: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderError;
