import axios from 'axios';
import { getApiURL } from '../api';

const fetchRegionList = (countryCode) =>
  axios.get(`${getApiURL()}v0/geodata/regions/`, {
    params: {
      country__code2: countryCode.toUpperCase(),
    },
  });

export default fetchRegionList;
