import React from 'react';

export default function Cart() {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H4.80952L5.32712 3.64921M5.32712 3.64921L7.19048 13.1864H18.1429L21 3.64921H13.1636H5.32712Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.5"
        cy="16.5"
        r="1.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="17.5"
        cy="16.5"
        r="1.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
