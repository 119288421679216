import React from 'react';
import styled from 'styled-components';
import visa from './icon-visa.svg';
import mastercard from './icon-mastercard.svg';
import mir from './icon-mir.svg';

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
`;

const IconImg = styled.img`
  width: 36px;
  height: 13px;
  margin-left: 10px;
`;

function RaiffesienPaySystemsIcons() {
  return (
    <IconsWrapper>
      <IconImg src={mastercard} style={{ width: '31px', height: '25px' }} />
      <IconImg
        style={{ alignSelf: 'center', width: '38px', height: '12px' }}
        src={mir}
        alt="mir"
      />
      <IconImg src={visa} alt="visa" />
    </IconsWrapper>
  );
}

export default RaiffesienPaySystemsIcons;
