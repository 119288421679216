import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../ui/common';

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 2;
  color: rgba(0, 0, 0, 0.8);
  input {
    width: 100%;
    border: none;
    padding: 0;
    height: 46px;
    font-size: 14px;
    outline: none;
    color: #000000;
  }
`;
const Button = styled.button`
  min-width: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: 4px;
  margin-right: 8px;
  color: #d8d8d8;
  border: 1px solid ${(props) => (props.error ? colors.error : 'currentColor')};
  border-radius: 4px;
  background: ${(props) => (props.value ? colors.color05 : 'transparent')};
  svg {
    display: block;
  }
`;

const Checkbox = React.forwardRef((props, ref) => {
  const { name, value, onChange, error, children, onBlur } = props;

  const handleChange = () => {
    onChange(name, !value);
  };

  const handleBlur = () => {
    if (onBlur) onBlur(name, value);
  };

  return (
    <Wrap onClick={handleChange} ref={ref}>
      <Button type="button" value={value} error={error} onBlur={handleBlur}>
        <Icon />
      </Button>
      <span>{children}</span>
    </Wrap>
  );
});
export default Checkbox;

export function Icon() {
  return (
    <svg
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      width="17"
      height="17"
    >
      <path d="M13.348 4.641a.5.5 0 01.011.707l-5.818 6a.5.5 0 01-.718 0L4.64 9.098a.5.5 0 11.718-.696l1.823 1.88 5.46-5.63a.5.5 0 01.706-.011z" />
    </svg>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.string,
};
Checkbox.defaultProps = {
  children: null,
  value: false,
  error: '',
  onBlur: null,
};
