import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Section, Row, Link } from './styled';

function Footer() {
  return (
    <Section>
      <Row>
        <Link to="/privacy-policy">
          <FormattedMessage id="footer.privacy" />
        </Link>
        <Link to="/terms">
          <FormattedMessage id="footer.terms" />
        </Link>
      </Row>
    </Section>
  );
}

export default Footer;
