import React, { useState, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentRequestButtonElement,
  ElementsConsumer,
  Elements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import getAmountInMinorUnits from '../../CreditCard/getAmountInMinorUnits';
import { Wrap, Row, Block, Number } from './styled';

const STRIPE_KEYS = {
  US: process.env.GATSBY_STRIPE_PUBLIC_TOKEN,
  EU: process.env.GATSBY_EU_STRIPE_PUBLIC_TOKEN,
};

const stripeInstances = {};
const getStripe = (loc) => {
  if (!stripeInstances[loc]) {
    stripeInstances[loc] = loadStripe(STRIPE_KEYS[loc]);
  }
  return stripeInstances[loc];
};

// https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react
const CheckoutForm = React.forwardRef((props, ref) => {
  const country = useSelector((state) => state.countries.picked);
  const currency = useSelector((state) => state.basket.defaultCurrency);
  const total = useSelector((state) => state.basket.total);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const handlePaymentMethod = async (event) => {
    try {
      const intentSecretId = props.getPaymentData().split(',')[1];
      const { paymentIntent, error: confirmError } =
        await props.stripe.confirmCardPayment(
          props.getPaymentData().split(',')[1],
          { payment_method: event.paymentMethod.id },
          { handleActions: false }
        );
      if (confirmError) {
        event.complete('fail');
        // eslint-disable-next-line no-console
        console.error(`Stripe confirm error:`, confirmError);
        Sentry.captureException(confirmError);
        props.onError();
        return;
      }

      event.complete('success');
      if (paymentIntent.status === 'requires_action') {
        const { error } = await props.stripe.confirmCardPayment(intentSecretId);
        if (error) {
          // eslint-disable-next-line no-console
          console.error(`Stripe required action error:`, confirmError);
          Sentry.captureException(confirmError);
          props.onError();
          return;
        }
      }
      props.onAuthorize();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Stripe payment method handler error:`, error);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const integratePaymentRequestButton = async () => {
      try {
        if (!props.getPaymentData || !props.stripe) return;

        const pr = props.stripe.paymentRequest({
          country,
          currency: currency.toLowerCase(),
          total: {
            label: 'Total',
            amount: getAmountInMinorUnits(total, currency),
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
        const result = await pr.canMakePayment();
        if (!result) {
          // eslint-disable-next-line no-console
          console.warn(`Can't make stripe payment via request button`);
          Sentry.captureMessage(
            `Can't make stripe payment via request button`,
            'warning'
          );
          return;
        }

        pr.on('paymentmethod', handlePaymentMethod);
        setPaymentRequest(pr);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Stripe payment request button error:`, error);
        Sentry.captureException(error);
      }
    };

    integratePaymentRequestButton();
  }, [props.stripe]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      if (!props.stripe || !props.elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const { stripe, elements, onAuthorize, onError, getPaymentData } = props;
      const cardElement = elements.getElement(CardNumberElement);
      if (getPaymentData) {
        const intentSecretId = getPaymentData().split(',')[1];
        stripe
          .confirmCardPayment(intentSecretId, {
            payment_method: { card: cardElement },
          })
          .then((result) => {
            if (result.error) {
              onError(result.error);
            } else {
              onAuthorize();
            }
          });
      } else {
        stripe.createToken(cardElement).then(({ token, error }) => {
          onAuthorize(token, error);

          if (onError) onError(error);
        });
      }
    },
  }));

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
      <form>
        <Wrap>
          <Row>
            <Number>
              <CardNumberElement />
            </Number>
            <Block>
              <CardExpiryElement />
            </Block>
            <Block>
              <CardCvcElement />
            </Block>
          </Row>
        </Wrap>
      </form>
    </>
  );
});
CheckoutForm.propTypes = {
  stripe: PropTypes.objectOf(PropTypes.any),
  elements: PropTypes.objectOf(PropTypes.any),
  onAuthorize: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  getPaymentData: PropTypes.func,
};
CheckoutForm.defaultProps = {
  stripe: null,
  elements: null,
  getPaymentData: null,
};

const StripeForm = React.forwardRef(({ loc, ...rest }, ref) => (
  <Elements stripe={getStripe(loc)}>
    <ElementsConsumer>
      {({ elements, stripe }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <CheckoutForm elements={elements} stripe={stripe} ref={ref} {...rest} />
      )}
    </ElementsConsumer>
  </Elements>
));
StripeForm.propTypes = {
  loc: PropTypes.string.isRequired,
};

export default StripeForm;
