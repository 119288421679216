export const errorCodes = Object.freeze({
  OK: 0,
  CUSTOMER: 1,
  SEND_ORDER: 2,
  UPDATE_ORDER: 3,
  EMIT_ORDER: 4,
  PAYMENT_NOT_CAPTURED: 5,
  AUTH_PAYPAL: 6,
  AUTH_SECURION: 7,
  AUTH_STRIPE: 8,
  PAYMENT_ID: 9,
  SHIPPING_ID: 10,
  OUT_OF_STOCK: 11,
  PAYMENT_PROVIDERS: 12,
  AUTH_AFFIRM: 13,
  AUTH_RAIFFEISEN: 14,
});

const prefix = 'checkout.main.errors.';

const errorText = {
  [errorCodes.OK]: '',
  [errorCodes.CUSTOMER]: `${prefix}customer`,
  [errorCodes.SEND_ORDER]: `${prefix}send_order`,
  [errorCodes.UPDATE_ORDER]: `${prefix}update_order`,
  [errorCodes.EMIT_ORDER]: `${prefix}emit_order`,
  [errorCodes.PAYMENT_NOT_CAPTURED]: `${prefix}payment_not_captured`,
  [errorCodes.AUTH_PAYPAL]: `${prefix}auth_paypal`,
  [errorCodes.AUTH_SECURION]: `${prefix}auth_securion`,
  [errorCodes.AUTH_STRIPE]: `${prefix}auth_stripe`,
  [errorCodes.PAYMENT_ID]: `${prefix}payment_id`,
  [errorCodes.SHIPPING_ID]: `${prefix}shipping_id`,
  [errorCodes.OUT_OF_STOCK]: `${prefix}out_of_stock`,
  [errorCodes.PAYMENT_PROVIDERS]: `${prefix}payment_providers`,
  [errorCodes.AUTH_AFFIRM]: `${prefix}auth_affirm`,
  [errorCodes.AUTH_RAIFFEISEN]: `${prefix}auth_raiffeisen`,
};

export const getCheckoutErrorText = (code) => {
  if (code && code > 0) return errorText[code];

  return '';
};
