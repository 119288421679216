import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import extractCyrillicName from '../Address/extractCyrillicName';
import FormWrapContext from '../Form/context';
import {
  Row,
  Title,
  Block,
  Button,
  Text,
  Type,
  Dot,
  Info,
  CustomerTitle,
  CustomerValue,
  Control,
  ShippingTitle,
  ShippingValue,
  BillingTitle,
  BillingValue,
} from './styled';

const getName = ({ locale, data }) => {
  const cyrillicName = extractCyrillicName(locale, data.alternate_names);
  return cyrillicName || data.name;
};

const getAddress = ({ locale, data, countryList }) => {
  const country = getName({
    locale,
    data: countryList.find((v) => v.id === data.country.id),
  });
  const city = data.city.name;
  const { street, info } = data;
  const state = getName({ locale, data: data.state });
  let addressLine = `${country} • ${state} • ${city} • ${street}`;
  if (info) {
    addressLine += ` • ${info}`;
  }
  return addressLine;
};

function Step(props) {
  const { children, active, info, onClick, short } = props;
  const { locale } = useIntl();
  const {
    firstScreenValues,
    values: { customer, shipping, billing },
  } = useContext(FormWrapContext);
  const countryList = useSelector((state) => state.countries.list);

  if (active < info.id) return null;
  return active === info.id ? (
    <Block first={info.id === 0}>
      <Dot>{info.id + 1}</Dot>
      <Title>
        <FormattedMessage id={info.title} />
      </Title>
      {children}
    </Block>
  ) : (
    <>
      {/* TODO Use current step comparison instead of `short` comparison */}
      {short === customer.email ? (
        <Info
          $isSameAddressForShippingAndBilling={
            firstScreenValues.isSameAddressForShippingAndBilling
          }
        >
          <CustomerTitle>
            <FormattedMessage id="checkout.customer.short" />
          </CustomerTitle>
          <CustomerValue>{customer.email}</CustomerValue>
          <Control>
            <Button onClick={onClick}>
              <FormattedMessage id="checkout.step.change" />
            </Button>
          </Control>

          <ShippingTitle>
            <FormattedMessage id="checkout.shipping.short" />
          </ShippingTitle>
          <ShippingValue>
            {getAddress({ locale, data: shipping, countryList })}
          </ShippingValue>

          {!firstScreenValues.isSameAddressForShippingAndBilling && (
            <>
              <BillingTitle>
                <FormattedMessage id="checkout.billing.short" />
              </BillingTitle>
              <BillingValue>
                {getAddress({ locale, data: billing, countryList })}
              </BillingValue>
            </>
          )}
        </Info>
      ) : (
        <Row>
          <Type>
            <FormattedMessage id={info.short} />
          </Type>
          <Text>{short}</Text>
          <Button onClick={onClick}>
            <FormattedMessage id="checkout.step.change" />
          </Button>
        </Row>
      )}
    </>
  );
}

export default Step;

Step.propTypes = {
  children: PropTypes.node.isRequired,
  info: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  short: PropTypes.string,
};

Step.defaultProps = {
  short: '',
};
