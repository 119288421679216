import { useSelector, useDispatch } from 'react-redux';

import { getItemPriceCurrency } from '../utils/api/items';
import ActionType from '../store/action-type';
import fetchItems from '../utils/api/fetchItems';
import { selectPickedCountryCode } from '../store/selectors/countries';
import selectBasketItemsToProcess from '../store/selectors/selectBasketItemsToProcess';
import createHandleBasketUpdateThunk from '../store/thunks/createHandleBasketUpdateThunk';

// TODO Move this flag to the store
let isInProgress = false;

const useApplyCountry = () => {
  const pickedCountryCode = useSelector(selectPickedCountryCode);
  const basketItemsToProcess = useSelector(selectBasketItemsToProcess);
  const dispatch = useDispatch();

  return async (countryCode) => {
    if (pickedCountryCode === countryCode || isInProgress) return;

    try {
      isInProgress = true;
      const items = await fetchItems(countryCode);
      dispatch({
        type: ActionType.Countries.Pick,
        payload: countryCode.toUpperCase(),
      });
      dispatch({ type: ActionType.Items.Add, payload: items });
      dispatch({
        type: ActionType.Basket.UpdateType,
        payload: {
          defaultCurrency: items[0] ? getItemPriceCurrency(items[0]) : 'USD',
        },
      });
      await dispatch(createHandleBasketUpdateThunk(basketItemsToProcess));
    } finally {
      isInProgress = false;
    }
  };
};

export default useApplyCountry;
