import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BreadcrumbItem, Breadcrumbs } from './styled';
import FormWrapContext from './context';
import { Arrow } from '../../Products/ui/Breadcrumbs';

function OrderBreadcrumps({ list, active }) {
  const { toStep, hideError } = useContext(FormWrapContext);

  return (
    <Breadcrumbs>
      {list.map((el) => {
        const onChangeActive = () => {
          if (active !== el.id) hideError();

          toStep(el.id);
        };

        return active >= el.id ? (
          <BreadcrumbItem key={el.id}>
            {el.id !== 0 && <Arrow />}
            <button type="button" onClick={onChangeActive}>
              <FormattedMessage id={el.short} />
            </button>
          </BreadcrumbItem>
        ) : null;
      })}
    </Breadcrumbs>
  );
}

OrderBreadcrumps.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  active: PropTypes.number.isRequired,
};

export default OrderBreadcrumps;
