import Big from 'big.js';
import axios from 'axios';
import { getApiURL } from '../api';
import { getStore } from '../../store';
import { getSessionId, setSessionId } from './session';
import { updatePriceItems } from './basket';
import { getPaymentId, setPaymentData } from './payment';
import { setShippingList, setCheckoutError, setPackages } from './checkout';
import { errorCodes } from './checkout-errors';
import { getAvailableVariants } from './cart';
import { initiateCheckout as fbPixelInitCheckout } from '../datalayer/dataLayer';

const getPromoCode = () => {
  const { promoCodeFinal } = getStore().getState().basket;
  return promoCodeFinal || null;
};

const getPreUpdateOrder = (fields) => {
  const billing =
    Object.keys(fields.billing).length > 0 ? fields.billing : fields.shipping;
  const commonInfo = {
    shipping_country: fields.shipping.country && fields.shipping.country.id,
    shipping_state: fields.shipping.state && fields.shipping.state.id,
    shipping_zip_code: fields.shipping.postalCode,
    shipping_city: fields.shipping.city && fields.shipping.city.name,
    shipping_address_line1: fields.shipping.street,
    shipping_address_line2: fields.shipping.info,

    customer_phone: fields.shipping.phone,
    customer_first_name: fields.shipping.firstName,
    customer_last_name: fields.shipping.lastName,
    customer_email: fields.customer.email,

    billing_first_name: billing.firstName,
    billing_last_name: billing.lastName,
    billing_phone: billing.phone,
    billing_country: billing.country.id,
    billing_state: billing.state.id,
    billing_city: billing.city.name,
    billing_address_line1: billing.street,
    billing_address_line2: billing.info,
    billing_zip_code: billing.postalCode,

    discount: getPromoCode(),
    agree_to_receive_marketing_materials: fields.shipping.newsletter,
    extra_data: { ozon: fields.method.additional },
  };
  if (!fields.method.id) return commonInfo;

  return {
    ...commonInfo,
    payment_provider: getPaymentId(),
    external_prepayment_data: fields.external_prepayment_data,
    shipping_rate: fields.method.id,
  };
};

/**
 * Parse prices value from prices data, it could be either order or possible_prices[i] object.
 * In second case do not forget to supply discount parameter.
 * @param pricesData
 */
function parsePrices(pricesData) {
  const shipping = new Big(pricesData.shipping_price.amount);
  const taxes = new Big(pricesData.vat_price.amount);
  const subtotal = new Big(pricesData.items_price.amount);
  const total = shipping.plus(taxes).plus(subtotal);
  const pureSubtotal = new Big(getStore().getState().basket.trueSubtotal);
  const oldPrice =
    pricesData.discount !== null
      ? pureSubtotal
          .plus(shipping)
          .times(taxes.div(subtotal.plus(shipping)).plus(1))
      : total;
  const discount = oldPrice.minus(total);

  return {
    subtotal: Number.parseFloat(subtotal.toFixed()),
    shipping: Number.parseFloat(shipping.toFixed()),
    taxes: Number.parseFloat(taxes.toFixed()),
    total: Number.parseFloat(total.toFixed()),
    oldPrice: Number.parseFloat(oldPrice.toFixed()),
    discount: Number.parseFloat(discount.toFixed()),
  };
}

const updateAdditional = async (result) => {
  let prices = null;
  let shippingRates = null;
  const possiblePrices = result.possible_prices;
  if (possiblePrices && possiblePrices.length > 0) {
    const minPrice = possiblePrices.reduce((a, b) =>
      parseFloat(a.shipping_price.amount) < parseFloat(b.shipping_price.amount)
        ? a
        : b
    );
    prices = parsePrices({ ...minPrice, discount: result.discount });

    shippingRates = possiblePrices.map((value) => ({
      id: value.shipping_rate_id,
      title: value.shipping_service.name,
      price: `${value.shipping_price.amount} ${value.shipping_price.currency}`,
      duration: value.shipping_duration,
    }));
  }
  if (result.vat_price) prices = parsePrices(result);

  if (prices) updatePriceItems(prices);
  if (shippingRates) setShippingList(shippingRates);
  setPackages(result.packages);
};

const getErrorCode = (data, response) => {
  if (!response.data) return errorCodes.UPDATE_ORDER;

  const isPaymentId =
    data.payment_provider &&
    response.data.payment_provider !== data.payment_provider;
  if (isPaymentId) return errorCodes.PAYMENT_ID;

  const isShippingId =
    data.shipping_rate &&
    (!response.data.shipping_rate ||
      response.data.shipping_rate !== data.shipping_rate);
  if (isShippingId) return errorCodes.SHIPPING_ID;

  return errorCodes.OK;
};

/**
 * Update order
 * @param orderData
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateOrder = (orderData) => {
  const apiURL = getApiURL();
  const sessionId = getSessionId();

  return axios.patch(`${apiURL}v0/orders/items/${sessionId}/`, orderData);
};

export const updateOrderItems = async (fields) => {
  const data = getPreUpdateOrder(fields);

  let errorCode;
  try {
    const response = await updateOrder(data);
    if (response.data) {
      updateAdditional(response.data);
    }
    errorCode = getErrorCode(data, response);
  } catch (error) {
    console.log('error', error);
    errorCode = errorCodes.UPDATE_ORDER;
  } finally {
    if (errorCode !== errorCodes.OK) {
      setCheckoutError(errorCode);
    }
  }
};

export const updateExistedOrder = (fields) => {
  const order = getPreUpdateOrder(fields);

  return updateOrder(order);
};

let lastOrderData = null;

/**
 * Creates new order on backend
 * @param orderData
 * @param {string} orderData.email               Customer email
 * @param [{Array.<Object>>}] orderData.variants Order variants array [{variant:, amount:}].
 * @returns {Promise<AxiosResponse<any>>}
 */
const createOrder = (orderData) =>
  axios.post(`${getApiURL()}v0/orders/items/`, {
    customer_email: orderData.email,
    order_variants: orderData.variants,
    ...orderData,
  });

export const handleOrder = async ({ values, isFirstStep }) => {
  const orderData = {
    email: values.customer.email,
    variants: getAvailableVariants(),
    ...getPreUpdateOrder(values),
    ...(isFirstStep ? { payment_provider: null, shipping_rate: null } : {}),
  };

  const isShouldCreateOrder =
    getSessionId() === null ||
    !lastOrderData ||
    ['shipping_country', 'customer_email'].some(
      (fieldName) => lastOrderData[fieldName] !== orderData[fieldName]
    );
  if (isShouldCreateOrder) {
    const response = await createOrder(orderData);
    updateAdditional(response.data);
    setSessionId(response.data.session_id);
    fbPixelInitCheckout();
  } else {
    const response = await updateOrder(orderData);
    updateAdditional(response.data);
    setPaymentData(response.data.internal_prepayment_data);
  }

  lastOrderData = orderData;
};
