import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Row = styled.div`
  display: flex;
  margin: 20px -10px;
  svg {
    vertical-align: middle;
  }
`;
const Link = styled.a`
  display: inline;
  margin: 0 10px;
  color: #000;
  font-size: 12px;
  text-decoration: none;
`;

function Breadcrumbs({ hideBread, productName }) {
  return (
    <Row id={hideBread === 'yes' ? 'hideBread' : ''}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link href="#">Evapolar</Link>
      <Arrow />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link href="#">{productName}</Link>
    </Row>
  );
}

Breadcrumbs.propTypes = {
  productName: PropTypes.string.isRequired,
  hideBread: PropTypes.string.isRequired,
};

export default Breadcrumbs;

export function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
      hanging="16"
      fill="none"
    >
      <path
        opacity=".5"
        d="M5.757 12L10 7.759 5.757 3.515"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
