import has from 'lodash/has';

const emailRegexp =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const nameRegexp = /^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/u;
const postalCodeRegexp = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/;
/* eslint-disable-next-line no-useless-escape */
const phoneRegExp = /^[0-9\-\(\)\+\. \/]{3,50}$/;
export const maxAddressLen = 100;
export const maxPhoneLen = 50;

const validators = {
  email: {
    required: true,
    isEmpty: (val) => !val.length,
    check: (val) => emailRegexp.test(val),
  },
  firstName: {
    required: true,
    isEmpty: (val) => !val.length,
    check: (val) => nameRegexp.test(val),
  },
  lastName: {
    required: true,
    isEmpty: (val) => !val.length,
    check: (val) => nameRegexp.test(val),
  },
  country: {
    required: true,
    isEmpty: (val) => !has(val, 'id'),
    check: () => true,
  },
  state: {
    required: true,
    isEmpty: (val) => !val.id || val.id === 0,
    check: () => true,
  },
  city: {
    required: true,
    isEmpty: (val) => val.name.length === 0,
    check: () => true,
  },
  street: {
    required: true,
    isEmpty: (val) => !val.length,
    check: (val) => val.length <= maxAddressLen,
  },
  phone: {
    required: true,
    isEmpty: (val) => !val.length,
    check: (val) => phoneRegExp.test(val),
  },
  info: {
    required: false,
    isEmpty: () => false,
    check: (val) => val.length <= maxAddressLen,
  },
  terms: {
    required: true,
    isEmpty: (val) => !val,
    check: () => true,
  },
  newsletter: {
    required: false,
    isEmpty: () => false,
    check: () => true,
  },
  postalCode: {
    required: true,
    isEmpty: (val) => !val,
    check: (val) => postalCodeRegexp.test(val),
  },
};

const validate = (obj, groupName) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const validator = validators[key];
    if (!validator) return acc;

    const { required, isEmpty, check } = validator;
    if (required && isEmpty(value))
      return { ...acc, [key]: `checkout.errors.${groupName}.${key}.required` };

    if (!check(value))
      return { ...acc, [key]: `checkout.errors.${groupName}.${key}.invalid` };

    return acc;
  }, {});

export const validateCustomer = (values) => validate(values, 'customer');

export const validateAddress = (values) => validate(values, 'address');
