import styled from 'styled-components';
import { colors, media } from '../../../ui/common';

export const Block = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${(p) => (p.first ? 'transparent' : '#e4e4e4')};
  padding-top: ${(p) => (p.first ? '0' : '41px')};
  margin-top: ${(p) => (p.first ? '0' : '39px')};
`;
export const Dot = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 14px;
  color: #ffffff;
  line-height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${colors.color05};
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 20px 16px;
  box-sizing: border-box;
  border: 1px solid #efefef;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  ${media.phone`
    padding: 26px 19px;
  `}
`;
export const Button = styled.button`
  background: transparent;
  border: none;
  padding: 2px 0;
  text-decoration: underline;
  font-weight: normal;
  color: ${colors.color05};
`;
export const Type = styled.span`
  color: rgba(0, 0, 0, 0.5);
  min-width: 64px;
  ${media.phone`
      min-width: 80px;
  `}
`;
export const Text = styled.span`
  flex: 1 0 0;
  box-sizing: border-box;
  word-break: break-all;
  padding: 0 8px;
  ${media.phone`
      padding: 0 16px;
  `}
`;
export const Title = styled.h1`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.color05};
  display: inline-block;
  margin-bottom: 24px;
`;

const getInfoGridTemplateAreas = (props) =>
  ''
    .concat(`"customer-title customer-value control"`)
    .concat(`"shipping-title shipping-value ."`)
    .concat(
      props.$isSameAddressForShippingAndBilling
        ? ''
        : `"billing-title billing-value ."`
    );
export const Info = styled.div`
  display: grid;
  grid-template-areas: ${getInfoGridTemplateAreas};
  column-gap: 16px;
  row-gap: 32px;
  font-size: 12px;
  padding: 26px 16px;
  border: 1px solid #efefef;
  grid-template-columns: min-content 1fr min-content;
`;
const InfoTitle = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
`;
const InfoValue = styled.p`
  margin: 0;
  word-break: break-word;
`;
export const CustomerTitle = styled(InfoTitle)`
  grid-area: customer-title;
`;
export const CustomerValue = styled(InfoValue)`
  grid-area: customer-value;
`;
export const Control = styled(InfoValue)`
  grid-area: control;
  white-space: nowrap;
`;
export const ShippingTitle = styled(InfoTitle)`
  grid-area: shipping-title;
`;
export const ShippingValue = styled(InfoValue)`
  grid-area: shipping-value;
`;
export const BillingTitle = styled(InfoTitle)`
  grid-area: billing-title;
`;
export const BillingValue = styled(InfoValue)`
  grid-area: billing-value;
`;
