import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ActionType from '../../../store/action-type';
import createHandleBasketUpdateThunk from '../../../store/thunks/createHandleBasketUpdateThunk';
import Customer from '../Customer';
import Shipping from '../Shipping';
import ShippingMethod from '../ShippingMethod';
import Billing from '../Billing';
import Payment from '../Payment';
import list from './data';
import FormWrapContext from './context';
import { handleOrder, updateOrderItems } from '../../../utils/api/orders';
import {
  reportCheckoutStep,
  reportOrderComplete,
} from '../../../utils/datalayer/dataLayer';
import OrderProcess from './orderProcess';
import OrderPlaced from './orderPlaced';
import { getSessionId } from '../../../utils/api/session';
import { getCheckoutError, setPaymentList } from '../../../utils/api/checkout';
import { errorCodes } from '../../../utils/api/checkout-errors';
import Cart from '../Cart';
import useI18nNavigation from '../../../hooks/use-i18n-navigation';
import useBasket from '../../../hooks/use-basket';
import { getCountryDataByCode } from '../../../utils/api/countries';
import {
  selectItemsCount,
  selectIsInvalidBasket,
} from '../../../store/selectors/basket';

import { DefaultShipping, DefaultCustomer } from '../data/default-values';
import OrderBreadcrumps from './orderBreadcrumps';
import OrderError from './orderError';
import OrderStep from './orderStep';
import { Actions, Next } from './styled';
import useSwitch from '../../../hooks/use-switch';
import useClearPayment from '../../../hooks/use-clear-payment';
import { validateAddress, validateCustomer } from './validation';

const steps = {
  FIRST_SCREEN: 0,
  SHIPPING_METHOD: 3,
  PAYMENT: 4,
};

function Form() {
  const { clearItems } = useBasket();
  const clearPayment = useClearPayment();
  const itemsCount = useSelector(selectItemsCount);
  const isInvalidBasket = useSelector(selectIsInvalidBasket);
  const navigate = useI18nNavigation();
  const basket = useSelector((state) => state.basket);
  const items = useSelector((state) => state.items);
  const { error } = useSelector((state) => state.checkout);
  const country = useSelector((state) => state.countries.picked);
  const dispatch = useDispatch();
  const [currentStepId, setCurrentStepId] = useState(steps.FIRST_SCREEN);
  const [paid, setPaid] = useState({ state: false });
  const [loader, setLoader] = useState({
    title: 'loading.order',
    isLoading: false,
  });

  // TODO Merge?
  const [values, setValues] = useState({
    customer: DefaultCustomer,
    shipping: {
      ...DefaultShipping,
      country: getCountryDataByCode(country),
    },
    method: {},
    billing: {
      ...DefaultShipping,
      country: getCountryDataByCode(country),
    },
    payment: {},
  });
  const [firstScreenValues, setFirstScreenValues] = useState({
    shipping: values.shipping,
    billing: values.billing,
    isSameAddressForShippingAndBilling: true,
  });
  const [firstScreenErrors, setFirstScreenErrors] = useState({
    customer: {},
    shipping: {},
    billing: {},
  });
  const {
    value: isFirstScreenHasBeenSubmitted,
    turnOn: notifyThatFirstScreenHasBeenSubmitted,
  } = useSwitch(false);
  const toggleSameAddressForShippingAndBilling = () => {
    setFirstScreenValues((prev) => ({
      ...prev,
      billing: { ...prev.shipping },
      isSameAddressForShippingAndBilling:
        !prev.isSameAddressForShippingAndBilling,
    }));
  };
  const handleChangeCustomer = (type, value) => {
    setValues((prev) => ({
      ...prev,
      customer: { ...prev.customer, [type]: value },
    }));

    dispatch({
      type: ActionType.Basket.UpdateType,
      payload: {
        shipping: null,
        taxes: null,
        total: null,
        oldPrice: null,
        discount: null,
      },
    });
    clearPayment();
  };
  const handleChangeShipping = (name, value) => {
    setFirstScreenValues((prev) => {
      const address = { ...prev.shipping, [name]: value };

      return {
        ...prev,
        shipping: address,
        billing: prev.isSameAddressForShippingAndBilling
          ? address
          : prev.billing,
      };
    });
  };
  const handleChangeBilling = (name, value) => {
    setFirstScreenValues((prev) => {
      const address = { ...prev.billing, [name]: value };

      return {
        ...prev,
        billing: address,
        shipping: prev.isSameAddressForShippingAndBilling
          ? address
          : prev.shipping,
      };
    });
  };
  useEffect(() => {
    if (!isFirstScreenHasBeenSubmitted) return;

    // TODO Use custom validators for shipping and billing if country === 'RU'
    setFirstScreenErrors((prev) => ({
      ...prev,
      shipping: validateAddress(firstScreenValues.shipping),
      billing: validateAddress(firstScreenValues.billing),
    }));
  }, [firstScreenValues]);
  useEffect(() => {
    if (!isFirstScreenHasBeenSubmitted) return;

    setFirstScreenErrors((prev) => ({
      ...prev,
      customer: validateCustomer(values.customer),
    }));
  }, [values]);

  const [cartOpened, setCartOpened] = useState(false);
  const [updateOrder, setUpdateOrderStatus] = useState(true);
  const errorRef = useRef(null);

  const showLoading = (title) =>
    setLoader((prev) => ({ isLoading: true, title: title || prev.title }));
  const hideLoading = () =>
    setLoader((prev) => ({ ...prev, isLoading: false }));
  const isLoading = () => loader.isLoading;

  const showError = (code, text) => {
    dispatch({
      type: ActionType.Checkout.SetError,
      payload: { code, text },
    });
    hideLoading();
  };
  const hideError = () => {
    dispatch({
      type: ActionType.Checkout.SetError,
      payload: { code: 0, text: '' },
    });
  };

  useEffect(() => {
    dispatch({ type: ActionType.Basket.Visible, payload: false });
  }, []);

  const shippingCountryCode = firstScreenValues.shipping.country?.code2 ?? '';
  useEffect(() => {
    if (!shippingCountryCode) return;

    setPaymentList(shippingCountryCode);
  }, [shippingCountryCode]);

  const toStep = (stepId) => {
    if (stepId < currentStepId) {
      setValues((prev) => ({ ...prev, payment: {} }));
      dispatch({ type: ActionType.Payment.SetId, payload: null });
    }
    setCurrentStepId(stepId);
  };

  const updateItemsForm = async (v) => {
    try {
      await updateOrderItems(v || values);
    } catch (e) {
      showError(errorCodes.EMIT_ORDER);
      console.log('error', e);
    }
  };

  // TODO Introduce selector
  const getOrderItems = () =>
    basket.list.map(({ id, count }) => ({
      ...items.list.find((variant) => variant.id === id),
      quantity: count,
    }));

  useEffect(() => {
    reportCheckoutStep(getOrderItems(), currentStepId + 1);
  }, [currentStepId]);

  const onPay = () => {
    const session = getSessionId();
    reportOrderComplete(getOrderItems(), {
      orderId: session,
      subtotal: basket.subtotal,
      shipping: basket.shipping,
      taxes: basket.taxes,
      currency: basket.defaultCurrency,
      discountName: basket.promoCodeFinal,
      discountAmount: basket.cartDiscount,
    });
    const shippingMethod = values.method.title;
    setPaid({
      state: true,
      session,
      shipping: shippingMethod,
      subtotal: basket.subtotal,
    });
    clearPayment();
    dispatch({ type: ActionType.Basket.Visible, payload: false });
    clearItems();
  };

  const onEmit = async (type, value, send) => {
    const newValues = { ...values, [type]: value };
    setValues(newValues);

    // Show anim.
    showLoading('loading.update_order');

    // LOCK update items checkout (IF not - it recreates order and cause an error).
    setUpdateOrderStatus(false);

    // Update basket (is valid on every step)
    await dispatch(createHandleBasketUpdateThunk());

    // Don't pass to the next step if basket is empty.
    if (isInvalidBasket) {
      dispatch({
        type: ActionType.Checkout.SetError,
        payload: { code: errorCodes.OUT_OF_STOCK, text: '' },
      });
    } else {
      // Everything is OK.
      dispatch({
        type: ActionType.Checkout.SetError,
        payload: { code: errorCodes.OK, text: '' },
      });

      // On step 2: send all info to the server and test for errors.
      if (send) {
        await updateItemsForm(newValues);
      }
    }

    // Unlock update items order.
    setUpdateOrderStatus(true);

    // Hide anim.
    hideLoading();

    const err = getCheckoutError();

    if (err.code !== errorCodes.OK) return;

    if (currentStepId < steps.PAYMENT) {
      toStep(currentStepId + 1);
    }
  };

  const onFirstScreenEmit = async (submittedValues) => {
    showLoading('loading.update_order');
    setCartOpened(false);

    try {
      await handleOrder({ values: submittedValues, isFirstStep: true });
    } catch (e) {
      hideLoading();

      showError(errorCodes.CUSTOMER);

      console.log('error', e);
    }

    setUpdateOrderStatus(false);

    await dispatch(createHandleBasketUpdateThunk());

    if (isInvalidBasket) {
      dispatch({
        type: ActionType.Checkout.SetError,
        payload: { code: errorCodes.OUT_OF_STOCK, text: '' },
      });
    } else {
      dispatch({
        type: ActionType.Checkout.SetError,
        payload: { code: errorCodes.OK, text: '' },
      });
    }

    setUpdateOrderStatus(true);

    hideLoading();

    const err = getCheckoutError();

    if (err.code !== errorCodes.OK) {
      return;
    }

    toStep(steps.SHIPPING_METHOD);
  };

  const handleSubmitFirstScreen = async () => {
    const newValues = { ...values, ...firstScreenValues };
    setValues(newValues);

    const newErrors = {
      customer: validateCustomer(values.customer),
      shipping: validateAddress(firstScreenValues.shipping),
      billing: validateAddress(firstScreenValues.billing),
    };
    setFirstScreenErrors(newErrors);

    notifyThatFirstScreenHasBeenSubmitted();

    const isValidData = Object.values(newErrors).every(
      (obj) => Object.keys(obj).length === 0
    );
    if (isValidData) {
      onFirstScreenEmit(newValues);
    }
  };

  useEffect(() => {
    /* don't send any info:
    - on 0 step.
    - on the last step when everything is sent.
    - if its locked. */
    if (currentStepId === steps.FIRST_SCREEN || paid.state || !updateOrder)
      return;

    const f = async () => {
      showLoading('loading.update_order');

      try {
        await handleOrder({ values, isFirstStep: false });
      } catch (e) {
        showError(errorCodes.EMIT_ORDER);
        console.log('error', e);
      } finally {
        hideLoading();
      }
    };

    f();
  }, [basket.list]);

  useEffect(() => {
    if (error.code === 0 || !errorRef) return;

    window.scrollTo(0, errorRef.current.offsetTop);
  }, [error]);

  useEffect(() => {
    if (paid.state) return;

    if (itemsCount === 0) {
      navigate('/');
    }
  }, [itemsCount]);

  const isRu = false;

  return (
    <FormWrapContext.Provider
      // TODO Wrap with useMemo()?
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        values,
        fields: values,
        firstScreenValues,
        firstScreenErrors,
        isFirstScreenHasBeenSubmitted,

        showLoading,
        hideLoading,
        isLoading,
        showError,
        hideError,
        toStep,
        onPay,
        setFirstScreenValues,
        notifyThatFirstScreenHasBeenSubmitted,
        handleChangeCustomer,
        handleChangeShipping,
        handleChangeBilling,
        toggleSameAddressForShippingAndBilling,
        handleSubmitFirstScreen,
      }}
    >
      {!paid.state && <Cart opened={cartOpened} />}
      <OrderProcess isLoading={loader.isLoading} title={loader.title} />
      {paid.state ? (
        <OrderPlaced values={paid} />
      ) : (
        <>
          <OrderBreadcrumps list={list} active={currentStepId} />
          {list.map((el) => {
            const handlePrev = (id) => {
              hideError();
              toStep(id);
            };

            return (
              <OrderStep info={el} active={currentStepId}>
                <OrderError error={error} ref={errorRef} />
                {el.id === steps.FIRST_SCREEN && (
                  <>
                    <Customer values={values} setValues={setValues} />
                    <Shipping isCityOnly={isRu} />
                    {!isRu && <Billing />}
                    <Actions>
                      <Next onClick={handleSubmitFirstScreen}>
                        <FormattedMessage id="checkout.shipping.continue" />
                      </Next>
                    </Actions>
                  </>
                )}
                {el.id === steps.SHIPPING_METHOD && (
                  <ShippingMethod
                    value={values.method}
                    onEmit={onEmit}
                    onPrev={() => handlePrev(steps.FIRST_SCREEN)}
                    ozonCity={values.shipping.city.name}
                  />
                )}
                {el.id === steps.PAYMENT && (
                  <Payment
                    onEmit={onEmit}
                    value={values.payment}
                    onPrev={() => handlePrev(steps.SHIPPING_METHOD)}
                  />
                )}
              </OrderStep>
            );
          })}
        </>
      )}
    </FormWrapContext.Provider>
  );
}

export default Form;
