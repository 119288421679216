import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import FormWrapContext from '../components/Checkout/Form/context';

// https://docs.affirm.com/affirm-developers/reference#data-types-4
// USD and CAN only, so exponent is always 2 - https://simple.wikipedia.org/wiki/ISO_4217
const MAJOR_MINOR_CURRENCY_K = 10 ** 2;

const getAfirmsShippingOrBilling = ({ shippingOrBilling, email }) => ({
  name: {
    first: shippingOrBilling.firstName,
    last: shippingOrBilling.lastName,
  },
  address: {
    line1: shippingOrBilling.street,
    line2: shippingOrBilling.info,
    city: shippingOrBilling.city.name,
    state: shippingOrBilling.state.name,
    country: shippingOrBilling.country.code2,
    zipcode: shippingOrBilling.postalCode,
  },
  email,
  phone_number: shippingOrBilling.phone,
});

const initAffirmCheckout = ({ basket, items, fields }) => {
  const { email } = fields.customer;

  window.affirm.checkout({
    config: {
      financial_product_key: process.env.GATSBY_AFFIRM_FINANCIAL_PRODUCT_KEY,
    },
    merchant: {
      user_cancel_url: 'https://www.affirm.com',
      user_confirmation_url: 'https://www.affirm.com',
      user_confirmation_url_action: 'GET',
      merchant: 'Evapolar',
    },
    shipping: getAfirmsShippingOrBilling({
      shippingOrBilling: fields.shipping,
      email,
    }),
    billing: getAfirmsShippingOrBilling({
      shippingOrBilling: fields.billing,
      email,
    }),
    metadata: {
      mode: 'modal',
    },
    items: basket.list.map((item) => ({
      sku: items.list.find(({ id }) => id === item.id).sku,
      unit_price: Number.parseFloat(item.price) * MAJOR_MINOR_CURRENCY_K,
      qty: item.quantity,
      display_name: item.name,
    })),
    total: basket.total * MAJOR_MINOR_CURRENCY_K,
  });
};

const useAffirm = ({ buttonRef, onAuthorize, onError }) => {
  const basket = useSelector((state) => state.basket);
  const items = useSelector((state) => state.items);
  const { fields } = useContext(FormWrapContext);

  useEffect(() => {
    initAffirmCheckout({ basket, items, fields });
    // eslint-disable-next-line no-param-reassign
    buttonRef.current.onclick = () => {
      window.affirm.checkout.open({
        onFail: (error) => {
          console.error('affirmError', error);
          onError({ message: error.reason });
        },
        onSuccess: (response) => {
          onAuthorize(response.checkout_token);
        },
      });
    };
  }, []);
};

export default useAffirm;
