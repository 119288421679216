import styled from 'styled-components';
import { media, colors } from '../../../ui/common';
import radio from '../ui/Radio';

export const Wrap = styled.div``;
export const List = styled.div`
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 5px 0 15px;
`;
export const Row = styled.div`
  ${radio};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  &:last-of-type {
    border-bottom: none;
  }
  &:after {
    background: ${(props) => (props.active ? colors.color05 : 'transparent')};
  }
  ${media.phone`
    padding-right: 18px;
  `}
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  opacity: 0.7;
`;
export const LeftBlock = styled.div`
  flex-basis: 50%;
`;
export const DeliveredTitle = styled(Title)`
  font-size: 12px;
  color: #28292e;
`;
