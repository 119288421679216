import React from 'react';
import PropTypes from 'prop-types';
import { isProduction } from '../utils/settings';

const merchantId = process.env.GATSBY_SHAREASALE_MERCHANT_ID;

function ShareASale({ values }) {
  const url = `https://www.shareasale.com/sale.cfm?
  tracking=${values.session}&
  amount=${values.subtotal}&
  merchantID=${merchantId}&
  transtype=sale`;

  return (
    isProduction && <img src={url} width="1" height="1" alt="integration" />
  );
}

ShareASale.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ShareASale;
