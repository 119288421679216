import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import FormWrapContext from './context';
import Step from '../Step';
import extractCyrillicName from '../Address/extractCyrillicName';

function OrderStep({ info, active, children }) {
  const { toStep, fields: values } = useContext(FormWrapContext);
  const { locale } = useIntl();

  const getShort = (type) => {
    const value = values[type];
    switch (type) {
      case 'customer':
        return value.email;
      case 'method':
        return value ? value.title : '';
      case 'payment':
        return '';
      default: {
        const arr = ['street', 'postalCode', 'city', 'country'].map((el) => {
          const data = value[el];

          if (typeof data === 'object') {
            const cyrillicName = extractCyrillicName(
              locale,
              data.alternate_names
            );

            return cyrillicName || data.name;
          }

          return data;
        });
        return arr.join(', ');
      }
    }
  };

  return (
    <Step
      key={info.id}
      info={info}
      active={active}
      onClick={() => toStep(info.id)}
      short={getShort(info.type)}
    >
      {children}
    </Step>
  );
}

OrderStep.propTypes = {
  info: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default OrderStep;
