import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Actions, Next, Prev } from '../Form/styled';
import { Row, Title, Body, Block, Text, List, Logo, Services } from './styled';
import Paypal from '../PaymentMethods/Paypal';
import StripeForm from '../PaymentMethods/Stripe';
import Affirm from '../PaymentMethods/Affirm';
import { updateExistedOrder } from '../../../utils/api/orders';
import { getPaymentData } from '../../../utils/api/payment';
import FormWrapContext from '../Form/context';
import { errorCodes } from '../../../utils/api/checkout-errors';
import { getCheckoutError } from '../../../utils/api/checkout';
import Raiffeisen from '../PaymentMethods/Raiffeisen';
import RaiffesienPaySystemsIcons from '../PaymentMethods/Raiffeisen/RaiffesienPaySystemsIcons';
import paymentMethods from '../../../domain/payment-methods';
import ActionType from '../../../store/action-type';

const [isStripe, isAdvStripe, isPaypal, isAffirm, isRaiffeisen] = [
  'stripe',
  'advstrp',
  'paypal',
  'Affirm',
  'Raiffeisen',
].map((searchString) => (paymentName) => paymentName.includes(searchString));

const useSortedPaymentList = () => {
  const rawPaymentList = useSelector((state) => state.checkout.paymentList);

  const stripeIndex = rawPaymentList.findIndex(({ name }) => isAdvStripe(name));
  if (stripeIndex === -1) return rawPaymentList;

  [rawPaymentList[0], rawPaymentList[stripeIndex]] = [
    rawPaymentList[stripeIndex],
    rawPaymentList[0],
  ];
  return rawPaymentList;
};

function Payment({ onPrev, onEmit }) {
  const { fields, showLoading, hideLoading, isLoading, showError, onPay } =
    useContext(FormWrapContext);
  const paymentList = useSortedPaymentList();
  const paymentData = useSelector((state) => state.payment.data);
  const inputRef = useRef(null);
  const advStripeRef = useRef(null);
  const [active, setActive] = useState(paymentList[0].name);
  const dispatch = useDispatch();

  const onClick = () => {
    // Show anim
    showLoading('loading.update_order');

    if (isStripe(active)) {
      inputRef.current.submit();
    } else if (isAdvStripe(active)) {
      advStripeRef.current.submit();
    }
  };

  const sendOrder = async (tokenId) => {
    // Update basket
    await onEmit('payment', { number: '', name: '', date: '', cvv: '' });

    // If is bad - do nothing
    const err = getCheckoutError();

    if (err.code !== errorCodes.OK) return;

    // Complete final order
    const order = { external_prepayment_data: tokenId };

    // Show anim
    showLoading('loading.order');

    updateExistedOrder({
      ...fields,
      ...order,
    })
      .then((orderResponse) => {
        if (orderResponse.data.payment_is_captured) {
          onPay();
        } else {
          showError(errorCodes.PAYMENT_NOT_CAPTURED);
        }

        // Hide anim
        hideLoading();
      })
      .catch((e) => {
        showError(errorCodes.SEND_ORDER);
        console.log('error', e);

        // Hide anim
        hideLoading();
      });
  };

  const onPayment = () => getPaymentData();

  /* Paypal */
  const onAuthorizePaypal = (response) => {
    if (response && response.payerID) sendOrder(response.payerID);

    return response;
  };

  const onErrorPaypal = (err) => {
    if (err) showError(errorCodes.AUTH_PAYPAL, err.message || '');
  };

  /* Stripe */
  const onAuthorizeStripe = (token, error) => {
    if (token && token.id) sendOrder(token.id);

    return [token, error];
  };

  /* Adv Stripe */
  const onAuthorizeStripeAdv = (token, error) => {
    console.log(token, error);
    hideLoading();
    onPay();
  };

  const onErrorStripe = (err) => {
    if (err) showError(errorCodes.AUTH_STRIPE, err.message);
  };

  /* Affirm */
  const onAuthorizeAffirm = (token) => {
    sendOrder(token);
  };

  const onErrorAffirm = (err) => {
    showError(errorCodes.AUTH_AFFIRM, err.message || 'Affirm error');
  };

  /* Raiffeisen */
  const onAuthorizeRaiffeisen = (token) => {
    sendOrder(token);
  };

  const onErrorRaiffeisen = (err) => {
    showError(errorCodes.AUTH_RAIFFEISEN, err || 'Raiffeisen error');
  };

  /* Handle update */
  const onUpdateMethod = (name) => {
    const method = paymentList.find((value) => value.name === name);
    const methodName = method.name.toLowerCase();

    const nameForMethodError = Object.keys(paymentMethods).find(
      (el) => !!methodName.includes(el.toLowerCase())
    );
    const errorCode = paymentMethods[nameForMethodError];

    if (method) {
      // Clear before make requests.
      dispatch({ type: ActionType.Payment.SetData, payload: '' });
      dispatch({ type: ActionType.Payment.SetId, payload: method.id });

      // Show anim
      showLoading('loading.payment');

      updateExistedOrder(fields)
        .then((res) => {
          dispatch({
            type: ActionType.Payment.SetData,
            payload: res.data.internal_prepayment_data,
          });

          // Hide anim
          hideLoading();
        })
        .catch((e) => {
          if (nameForMethodError) {
            showError(errorCodes[errorCode]);
            console.log('error', e);

            hideLoading();
          } else {
            showError(errorCodes.UPDATE_ORDER);
            console.log('error', e);

            // Hide anim
            hideLoading();
          }
        });
    }
  };

  useEffect(() => {
    // Clear before make requests.
    dispatch({ type: ActionType.Payment.SetData, payload: '' });

    if (active) onUpdateMethod(active);
  }, []);

  const blockRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  const isShowPlaceOrder =
    typeof active === 'string' &&
    !isPaypal(active) &&
    !isAffirm(active) &&
    !isRaiffeisen(active);

  return (
    <div>
      <Text>
        <FormattedMessage id="checkout.payment.text" />
      </Text>
      <List ref={blockRef}>
        {paymentList.map((el) => (
          <Block key={el.name}>
            <Row
              key={el.name}
              active={el.name === active}
              onClick={() => {
                onUpdateMethod(el.name);
                setActive(el.name);
              }}
            >
              {el.title && <Title>{el.title}</Title>}
              {isRaiffeisen(el.name) && <RaiffesienPaySystemsIcons />}
              {el.logo && (
                <Logo>
                  <img src={el.logo} alt="" />
                </Logo>
              )}
              {el.services && (
                <Services>
                  <img src={el.services} alt="" />
                </Services>
              )}
            </Row>
            <Body active={el.name === active}>
              {isAdvStripe(el.name) && (
                <StripeForm
                  onAuthorize={onAuthorizeStripeAdv}
                  getPaymentData={getPaymentData}
                  ref={advStripeRef}
                  loc={el.name.split(' ')[0].toUpperCase()}
                  onError={onErrorStripe}
                />
              )}
              {isPaypal(el.name) && paymentData !== '' && (
                <Paypal
                  onPayment={onPayment}
                  onAuthorize={onAuthorizePaypal}
                  onError={onErrorPaypal}
                />
              )}
              {isStripe(el.name) && (
                <StripeForm
                  onAuthorize={onAuthorizeStripe}
                  ref={inputRef}
                  loc={el.name.split(' ')[0].toUpperCase()}
                  onError={onErrorStripe}
                />
              )}
              {isAffirm(el.name) && (
                <Affirm
                  onAuthorize={onAuthorizeAffirm}
                  onError={onErrorAffirm}
                />
              )}
              {isRaiffeisen(el.name) && (
                <Raiffeisen
                  onAuthorize={onAuthorizeRaiffeisen}
                  onError={onErrorRaiffeisen}
                />
              )}
            </Body>
          </Block>
        ))}
      </List>
      <Actions>
        {isShowPlaceOrder && (
          <Next disabled={isLoading()} onClick={onClick}>
            <FormattedMessage id="checkout.payment.place_order" />
          </Next>
        )}
        <Prev onClick={() => onPrev()}>
          ⟵{' '}
          <span>
            <FormattedMessage id="checkout.billing.return_shipping" />
          </span>
        </Prev>
      </Actions>
    </div>
  );
}

Payment.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onEmit: PropTypes.func.isRequired,
};

export default Payment;
