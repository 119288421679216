import axios from 'axios';
import { getApiURL } from '../api';

export default async (countryCode) => {
  const response = {
    status: 'error',
    code: 520,
    data: [],
  };
  try {
    const res = await axios.get(`${getApiURL()}v0/orders/payment_providers/`, {
      params: {
        countries__code2: countryCode.toUpperCase(),
      },
    });
    if (res.status !== 200) {
      response.code = res.status;
      return response;
    }
    response.code = res.status;
    response.status = res.statusText;
    response.data = res.data;
    return response;
  } catch (e) {
    console.warn(e);
    response.errors = e.message;
    return response;
  }
};
