import React from 'react';
import { FormattedMessage } from 'react-intl';
import lock from '../images/lock.svg';
import { Section, Row, Block, LogoLocalizedLink } from './styled';
import LogoIcon from '../../Logo';
import { colors } from '../../../ui/common';

function Header() {
  return (
    <Section>
      <Row>
        <LogoLocalizedLink to="/">
          <LogoIcon textColor={colors.color14} />
        </LogoLocalizedLink>
        <Block>
          <img src={lock} alt="evapolar" />
          <span>
            <FormattedMessage id="checkout.header.secure" />
          </span>
        </Block>
      </Row>
    </Section>
  );
}

export default Header;
